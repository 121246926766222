import React from "react";

const ModalContainer = ({ handleClose, children }) => {
  return (
		<div>
			<div
				onClick={e => e.target === e.currentTarget && handleClose()}
				className="tw-fixed tw-inset-0 tw-w-full tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-primary tw-bg-opacity-50 tw-backdrop-blur-sm">
				<div className="tw-w-full tw-max-w-md  tw-bg-white tw-rounded-lg tw-shadow-sm tw-transition-all tw-ease-in-out tw-duration-700 p-4 tw-max-h-[600px] tw-overflow-y-auto">
					{" "}
					{children}
				</div>
			</div>
		</div>
	);
};

export default ModalContainer;
