import React from "react";
import ModalContainer from "./modalcontainer";
import PrimaryBtn from "../button";

const DeclineIntentModal = ({
	handleClose,
	handleDone,
	loading,
	reason,
	setReason,
}) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div>
					<h4 className="tw-text-xl tw-font-medium tw-text-primary satoshi">
						Reason for Decline
					</h4>
					<textarea
						name="reason"
						id=""
						value={reason}
						onChange={e => setReason(e?.target?.value)}
						style={{ resize: "none", height: "10rem" }}
						className="tw-h-32 tw-w-full tw-border tw-border-[#7B87944D] tw-rounded-xl tw-text-sm tw-font-medium tw-text-primary satoshi"
					/>
					<div className="tw-mt-8 tw-flex tw-justify-end">
						<div className="tw-flex tw-gap-5 tw-items-center">
							<PrimaryBtn
								onClick={handleClose}
								bg={"white"}
								text={"Cancel"}
								color={"#002F19"}
							/>
							<PrimaryBtn
								onClick={handleDone}
								bg={"#59FE9C"}
								text={"Proceed"}
								color={"#002F19"}
								loading={loading}
							/>
						</div>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default DeclineIntentModal;
