import React from "react";
import ModalContainer from "./modalcontainer";
import Done from "../../assets/done.svg";
import Decline from "../../assets/decline.svg";
import Accept from "../../assets/accept.svg";
import PrimaryBtn from "../button";

const DoneModal = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer handleClose={handleClose}>
        <div>
          <img src={Done} alt="" className="tw-mx-auto" />
          <h4 className="tw-pt-5 tw-text-2xl tw-font-extrabold tw-text-primary satoshi tw-text-center">
            Proposal sent
          </h4>
          <p className="tw-text-center tw-font-normal tw-text-sm tw-text-primary satoshi">
            Your proposal has been forwarded to the artist, the admin will get
            back to you as soon as the artist response to your proposal
          </p>
          <div className="tw-mt-8">
            <button
              onClick={handleClose}
              className="tw-h-12 tw-w-full tw-rounded-xl tw-text-sm tw-font-semibold satoshi tw-bg-[#59FE9C] tw-text-darkblue"
            >
              Done
            </button>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export const DoneModalAccept = ({ handleClose, type, order }) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div>
					{type === "accept" ? (
						<img src={Accept} alt="" className="" />
					) : (
						<img src={Decline} alt="" className="" />
					)}
					{/* <img
            src={type === "accept" ? { Accept } : { Decline }}
            alt=""
            className=""
          /> */}
					<h4 className="tw-pt-5 tw-text-2xl tw-font-extrabold tw-text-primary satoshi">
						Proposal {type === "accept" ? "Accepted" : "Declined"}
					</h4>
					<p className="tw-font-normal tw-text-sm tw-text-primary satoshi">
						{type === "accept"
							? "You have approved this proposal a notification would be send to the client and you will be notified when the payment is made"
							: `(Order ${order?.title || ""}) has been decline `}
					</p>
					<div className="tw-mt-8 tw-flex tw-justify-end">
						<PrimaryBtn
							onClick={handleClose}
							bg={"#59FE9C"}
							text={"Okay"}
							color={"#002F19"}
						/>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export const UpdateDone = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer handleClose={handleClose}>
        <div>
          <img src={Done} alt="" className="tw-mx-auto" />
          <h4 className="tw-pt-5 tw-text-2xl tw-font-extrabold tw-text-primary satoshi tw-text-center">
            Update Request Successful
          </h4>

          <div className="tw-mt-8">
            <button
              onClick={handleClose}
              className="tw-h-12 tw-w-full tw-rounded-xl tw-text-sm tw-font-semibold satoshi tw-bg-[#59FE9C] tw-text-darkblue"
            >
              Done
            </button>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default DoneModal;
