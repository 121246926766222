import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";

const MainHome = () => {
  let navigate = useNavigate();
  let { isAuth } = useAuthStore();
	useEffect(() => {
		if (isAuth) {
			navigate("/client-home");
		}
	}, [navigate, isAuth]);
  return <div></div>;
};

export default MainHome;
