import Header from "../components/header";
import { MainProfileSection } from "../talent/edit-profile";

const EditProfile = () => {
	return (
		<div>
			<section className="tw-bg-white tw-min-h-screen tw-w-full tw-pt-12">
				<Header bg={"#002F19"} />
				<MainProfileSection />
			</section>
		</div>
	);
};

export default EditProfile;
