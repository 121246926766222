import React, { useState, useEffect, useContext } from "react";
import Logo from "./logo";
import PrimaryBtn from "./button";
import { useNavigate } from "react-router-dom";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import Notification from "../assets/notification-bing.svg";
import Profile from "../assets/profile2.svg";
import User from "../assets/user.svg";
import Verified from "../assets/verified.svg";
import { FaAngleRight } from "react-icons/fa";
import Debit from "../assets/debit.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import useNotificationStore from "../data/Stores/notificationstore";
import { LoadMore, MainPaginate } from "./Pagination";
import usePaymentStore from "../data/Stores/paymentstore";
import moment from "moment";
import { GlobalState } from "../data/Context";

const Header = ({ bg }) => {
	const navigate = useNavigate();
	const { isAuth, userPage, resetUserPage, logout, user } = useAuthStore(),
		[child, setChild] = useState(""),
		[nav, setNav] = useState(false),
		handleLogout = () => {
			resetUserPage();
			logout();
			navigate("/");
		};
	const links = [
		{ name: "About", action: () => navigate("/about-us"), show: true },
		{
			name: "Find talent",
			action: () => navigate("/categories"),
			show: !isAuth || user?.privilege === "client",
		},
		{
			name: "personal development",
			action: () => navigate("/personal"),
			show: !isAuth || user?.privilege === "creative",
		},
		{
			name: "chat",
			action: () => (isAuth ? navigate("/chat") : navigate("/login")),
			show: user?.privilege,
		},
		{
			name: "orders",
			action: () => (isAuth ? navigate("/orders") : navigate("/login")),
			show: user?.privilege,
		},
	];
	const Notifications = [
		{
			title: "Admin Response",
			desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
		},
		{
			title: "Admin Response",
			desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
		},
		{
			title: "Admin Response",
			desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
		},
	];
	return (
		<div>
			<div
				style={{
					background: bg,
				}}
				className="tw-w-full tw-h-20 tw-backdrop-blur-md tw-border-b tw-border-b-[#FFFFFF]">
				<div className="tw-flex container tw-justify-between tw-items-center tw-h-full">
					<Logo />
					<div className="lg:tw-flex tw-hidden tw-gap-6 tw-items-center">
						{links
							?.filter(it => it?.show)
							?.map((it, i) =>
								isAuth &&
								it.name === "personal development" &&
								userPage !== null &&
								userPage !== "talent" ? null : (
									<div onClick={it?.action} key={i} className="">
										<strong
											className={`tw-text-base tw-cursor-pointer tw-capitalize tw-font-medium satoshi tw-text-white`}>
											{it?.name}
										</strong>
									</div>
								)
							)}
					</div>

					{isAuth ? (
						<div className="tw-flex tw-relative tw-gap-5 tw-items-center">
							<img
								onClick={() => setChild(child !== "" ? "" : "notification")}
								src={Notification}
								alt=""
								className="tw-cursor-pointer"
							/>
							<img
								onClick={() => setChild(child !== "" ? "" : "profile")}
								src={user?.image?.url || Profile}
								alt=""
								className={`tw-cursor-pointer tw-rounded-full ${
									user?.image?.url ? " tw-h-8 tw-w-8" : ""
								}`}
							/>
							<div>
								<div className="tw-block lg:tw-hidden">
									{" "}
									{nav ? (
										<IoClose
											onClick={() => setNav(false)}
											color="white"
											className="tw-cursor-pointer"
										/>
									) : (
										<GiHamburgerMenu
											onClick={() => setNav(true)}
											className="tw-cursor-pointer"
											color="white"
											size={20}
										/>
									)}
								</div>
							</div>
							{child === "notification" && (
								<ClientNotification Notifications={Notifications} />
							)}
							{child === "profile" && (
								<ClientProfile
									handleLogout={handleLogout}
									handleNotifications={() => setChild("notification")}
									handleOrders={() => setChild("orders")}
									handleTransactions={() => setChild("transactions")}
									navigate={navigate}
								/>
							)}
							{child === "transactions" && <Transactions />}
							{child === "orders" && <Orders Notifications={Notifications} />}
						</div>
					) : (
						<div className="tw-flex tw-gap-5 tw-items-center">
							<div className="tw-hidden lg:tw-block">
								<PrimaryBtn
									onClick={() => navigate("/register")}
									bg={"transparent"}
									color={"white"}
									text={"Sign Up"}
								/>
							</div>
							<div className="tw-hidden lg:tw-block">
								<PrimaryBtn
									onClick={() => navigate("/login")}
									color={"#010031"}
									bg={"#59FE9C"}
									text={"Log In"}
								/>
							</div>
							<div className="tw-block lg:tw-hidden">
								{" "}
								{nav ? (
									<IoClose
										onClick={() => setNav(false)}
										color="white"
										className="tw-cursor-pointer"
									/>
								) : (
									<GiHamburgerMenu
										onClick={() => setNav(true)}
										className="tw-cursor-pointer"
										color="white"
										size={20}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			{nav && (
				<MobileNav
					navigate={navigate}
					auth={isAuth}
					userPage={userPage}
					links={links?.filter(it => it?.show)}
				/>
			)}
		</div>
	);
};

const ClientNotification = ({ Notifications }) => {
	const [datum, setDatum] = useState(null),
		{ data, getNotification } = useNotificationStore(),
		navigate = useNavigate();

	useEffect(() => {
		setDatum(data);
	}, [data]);

	useEffect(() => {
		apiCall("get", `/api/v1/notification`, null, getNotification);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[search] = useState(""),
		[loading, setLoading] = useState(null),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/notification&limit=${datum?.limit * datum?.nextPage}${
				search ? `&search=${search}` : ""
			}`,
			null,
			getNotification
		);
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-absolute tw-top-14 tw-w-[450px] tw-right-0 tw-z-50 tw-bg-white tw-max-h-96 tw-shadow-xl tw-overflow-y-scroll tw-rounded-2xl tw-p-5">
				<div className="tw-max-h-full noScroll">
					<h2 className="tw-text-xm tw-font-[900] tw-text-pretty satoshi">
						Notifications
					</h2>
					<div className="tw-space-y-3">
						{currentItems?.map((it, i) => (
							<div
								key={i}
								className="tw-p-3 tw-mt-5 tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D]">
								<strong className="tw-text-base tw-font-medium satoshi tw-text-primary tw-capitalize">
									{it?.title}
								</strong>
								<div>
									<small className="tw-text-xs tw-font-normal satoshi tw-text-primary">
										{it?.message}
									</small>
								</div>
								<div
									onClick={() => {
										if (it?.idPath === "Order") navigate("/orders");
									}}
									className="tw-mt-3 tw-flex tw-justify-end tw-cursor-pointer">
									<strong className="tw-text-xs tw-font-bold tw-text-black tw-underline satoshi">
										View
									</strong>
								</div>
							</div>
						))}
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const ClientProfile = ({
	handleOrders,
	handleNotifications,
	handleTransactions,
	handleLogout,
	navigate,
}) => {
	let notify = useNotificationStore();

	const Arr = [
		{
			name: "notifications",
			value: notify?.data?.totalDocs,
			action: handleNotifications,
		},
		{
			name: "transactions",
			action: handleTransactions,
		},
	];

	const { user } = useAuthStore();

	return (
		<div>
			<div className="tw-absolute tw-top-14 tw-w-[450px] tw-right-0 tw-z-50 tw-bg-white tw-p-8 tw-shadow-xl tw-rounded-2xl">
				<div className="tw-flex tw-justify-between tw-items-center">
					<strong className="tw-text-xm tw-font-bold lato tw-text-primary">
						Profile
					</strong>
					<PrimaryBtn
						bg={"#EEFFF5"}
						onClick={() => navigate("/edit-profile")}
						color={"black"}
						text={"Edit Profile"}
					/>
				</div>
				<div className="tw-flex tw-justify-between tw-mt-4 tw-items-center">
					<div className="tw-flex tw-gap-2 tw-items-center">
						<img
							src={user?.image?.url || User}
							alt=""
							className={`tw-rounded-full ${
								user?.image?.url ? "tw-h-16 tw-w-16 " : ""
							}`}
						/>
						<div>
							<strong className="tw-text-base tw-font-bold tw-text-primary satoshi">
								{user?.firstName} {user?.lastName}
							</strong>
							<div>
								<small className="tw-text-base tw-font-normal satoshi tw-text-primary tw-uppercase">
									{user?.privilege}
								</small>
							</div>
							{user?.statusText !== "pending" && (
								<div>
									<small className="tw-text-base tw-font-normal satoshi tw-text-primary">
										Verified
									</small>
								</div>
							)}
						</div>
					</div>
					<div>
						{user?.statusText !== "pending" && (
							<div className="tw-flex tw-justify-end">
								<img src={Verified} alt="" className="" />
							</div>
						)}
						<div>
							<small className="tw-text-base tw-font-normal satoshi tw-text-primary">
								{user?.email}
							</small>
						</div>
					</div>
				</div>
				<div className="tw-mt-5">
					{Arr?.map(it => (
						<div
							onClick={it?.action}
							className="tw-border-b-[0.7px] tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-border-b-[#C2BDBD] tw-py-3">
							<div>
								<strong className="tw-text-primary tw-capitalize tw-font-medium satoshi lg:tw-text-xl">
									{it?.name}
								</strong>
							</div>
							<strong className="tw-text-primary tw-font-medium satoshi lg:tw-text-xl">
								{it?.value ? it?.value : <FaAngleRight />}
							</strong>
						</div>
					))}
				</div>
				<div className="tw-mt-3 tw-flex tw-justify-end">
					<strong
						onClick={handleLogout}
						className="tw-text-base tw-font-bold tw-cursor-pointer tw-text-black tw-underline satoshi">
						Logout
					</strong>
				</div>
			</div>
		</div>
	);
};

const Transactions = () => {
  // const Arr = [
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // 	{
  // 		type: "debit",
  // 		info: "Jog John / Flutterwaves",
  // 		amount: "N5,300",
  // 		time: "Today, 10:23pm",
  // 	},
  // ];

  const [datum, setDatum] = useState(null),
    { data, getPayment } = usePaymentStore(),
    { nairaSign, numberWithCommas } = useContext(GlobalState);

  useEffect(() => {
    setDatum(data);
  }, [data]);

  useEffect(() => {
    apiCall("get", `/api/v1/order/payment`, null, getPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [range] = useState(10),
    [page, setPage] = useState(1),
    [search] = useState(""),
    [loading, setLoading] = useState(null),
    [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
    setPage(1 + event?.selected);
  };

  let handleLoadMore = async () => {
    setLoading("loadmore");

    await apiCall(
      "get",
      `/api/v1/order/payment&limit=${datum?.limit * datum?.nextPage}${
        search ? `&search=${search}` : ""
      }`,
      null,
      getPayment
    );
    setLoading(false);
  };

  return (
    <div>
      <div className="tw-absolute tw-top-14 tw-w-[450px] tw-right-0 tw-z-50 tw-bg-white tw-max-h-96 tw-shadow-xl tw-overflow-y-scroll tw-rounded-2xl tw-p-5">
        <h3 className="lg:tw-text-3xl tw-text-xl tw-font-[900] tw-text-primary satoshi">
          Transaction History
        </h3>
        <div className="tw-max-h-full noScroll">
          <div className="tw-mt-5">
            {currentItems?.map((it, i) => (
              <div
                key={i}
                className="tw-border-b-[0.7px] tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-border-b-[#C2BDBD] tw-py-3"
              >
                <div className="tw-flex tw-gap-2 tw-items-center">
                  <img src={Debit} alt="" className="" />
                  <div>
                    <strong className="lg:tw-text-xl tw-text-sm tw-font-bold tw-text-primary satoshi tw-capitalize">
                      {it?.type?.title}{" "}
                      {it?.provider || it?.statusText || it?.status ? (
                        <> / {it?.provider || it?.statusText || it?.status}</>
                      ) : null}
                    </strong>
                    <div>
                      <small className="lg:tw-text-base tw-text-xs tw-font-normal satoshi tw-text-primary">
                        {it?.paidOn || it?.createdAt
                          ? moment(it?.paidOn || it?.createdAt).format(
                              "Do MMMM, YYYY hh:mmA"
                            )
                          : null}
                      </small>
                    </div>
                  </div>
                </div>
                <div>
                  <strong className="tw-text-base tw-text-black tw-font-medium satoshi">
                    {nairaSign}
                    {numberWithCommas(
                      Number(
                        it?.amount || it?.payment?.amount || it?.fee || 0
                      ).toFixed(2)
                    )}
                  </strong>
                </div>
              </div>
            ))}
          </div>
          <div className="tw-mt-4 tw-flex tw-justify-center">
            <LoadMore
              next={page === pageCount && datum?.hasNextPage}
              loading={loading === "loadmore"}
              handleLoadMore={handleLoadMore}
            />
          </div>
          {pageCount > 1 && (
            <MainPaginate
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Orders = ({ Notifications }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="tw-absolute tw-top-14 tw-w-[450px] tw-right-0 tw-z-50 tw-bg-white tw-max-h-96 tw-shadow-xl tw-overflow-y-scroll tw-rounded-2xl tw-p-5">
        <div className="tw-max-h-full noScroll">
          <h2 className="lg:tw-text-xm tw-text-base tw-font-[900] tw-text-pretty satoshi">
            Orders
          </h2>
          <div className="tw-space-y-3">
            {Notifications?.map((it, i) => (
              <div
                key={i}
                className="tw-p-3 tw-mt-5 tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D]"
              >
                <strong className="tw-text-base tw-font-medium satoshi tw-text-primary">
                  {it?.title}
                </strong>
                <div>
                  <small className="tw-text-xs tw-font-normal satoshi tw-text-primary">
                    {it?.desc}
                  </small>
                </div>
                <div className="tw-mt-3 tw-flex tw-justify-end">
                  <strong
                    onClick={() => navigate("/orders")}
                    className="tw-text-xs tw-cursor-pointer tw-font-bold tw-text-black tw-underline satoshi"
                  >
                    View
                  </strong>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileNav = ({ links, auth, userPage, navigate }) => {
  return (
    <div className="tw-fixed tw-z-50 tw-w-full tw-p-6 tw-bg-white">
      <div className="tw-mt- tw-space-y-4">
        {links?.map((it) =>
          (it.name === "chat" || it.name === "orders") &&
          !auth ? null : it.name === "personal development" &&
            userPage !== "talent" ? null : (
            <div onClick={it?.action} className="">
              <strong
                className={`tw-text-base tw-cursor-pointer tw-capitalize tw-font-bold satoshi tw-text-primary`}
              >
                {it?.name}
              </strong>
            </div>
          )
        )}
      </div>
      {!auth && (
        <div className="tw-flex tw-gap-3 tw-mt-6">
          {" "}
          <div className="">
            <PrimaryBtn
              onClick={() => navigate("/register")}
              bg={"#002F19"}
              color={"white"}
              text={"Sign Up"}
            />
          </div>
          <div className="">
            <PrimaryBtn
              onClick={() => navigate("/login")}
              color={"#010031"}
              bg={"#59FE9C"}
              text={"Log In"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
