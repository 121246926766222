import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import Profile from "../../assets/profile.svg";
import Star from "../../assets/star.svg";
import PrimaryBtn from "../../components/button";
import Img from "../../assets/details.png";
import { IoCloseCircleOutline } from "react-icons/io5";
import Avatar from "../../assets/review.svg";
import OrderModal from "../../components/modals/ordermodal";
import DoneModal from "../../components/modals/donemodal";
import { useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../../data/Stores/userstore";
import { GlobalState } from "../../data/Context";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { createMarkup } from "../../talent/edit-profile";

const ArtistDetails = () => {
	return (
		<div>
			<div className="tw-w-full tw-bg-white tw-pt-12">
				<Header bg={"#002F19"} />
				<MainSection />
			</div>
		</div>
	);
};

const MainSection = () => {
	const [modal, setModal] = useState("");
	const items = useLocation()?.state,
		[state, setState] = useState(null),
		{ data, getUsers } = useUserStore(),
		{ nairaSign, numberWithCommas } = useContext(GlobalState),
		navigate = useNavigate(),
		{ user } = useAuthStore();

	useEffect(() => {
		if (!items) navigate(-1);
	}, [items, navigate]);

	useEffect(() => {
		data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [data, items]);

	useEffect(() => {
		if (items)
			apiCall(
				"get",
				`/api/v1/user/manage-creatives?privilege=creative${
					items?.category ? `&category=${items?.category?.[0]?._id}` : ""
				}`,
				null,
				getUsers
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (!state) return;

	return (
		<section className="tw-w-full tw-py-16 tw-bg-white">
			<div className="container">
				<img
					src={state?.image?.url || Profile}
					alt=""
					className="tw-h-24 tw-rounded-full"
				/>
				<h5 className="tw-text-2xl tw-font-[900] tw-text-primary satoshi">
					{state?.firstName} {state?.lastName}
				</h5>
				<img src={Star} alt="" className="" />
				<div className="lg:tw-w-1/2">
					<div className="tw-w-full tw-flex tw-mt-6 tw-justify-between tw-border-b-[#00085166] tw-border-b-[0.5px] tw-pb-8 tw-items-center">
						<div>
							<strong className="tw-text-base tw-text-primary satoshi tw-font-bold">
								{nairaSign}{" "}
								{numberWithCommas(Number(state?.serviceFee || 0).toFixed(2))}{" "}
								<span className="tw-text-xs tw-font-medium">
									{state?.suffix || `Per show`}{" "}
								</span>
							</strong>
						</div>
						{user?.privilege === "client" && (
							<PrimaryBtn
								onClick={() => setModal("order")}
								bg={"#59FE9C"}
								color={"black"}
								text={"Get in touch"}
							/>
						)}
					</div>
					<div className="tw-mt-8">
						<DetailsCard>
							<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
								Area of expertise
							</h4>
							<strong className="tw-text-base tw-font-bold tw-text-primary satoshi">
								{state?.category?.[0]?.title}
							</strong>
						</DetailsCard>
						<p className="tw-text-base tw-w-full tw-text-primary tw-font-normal satoshi">
							{state?.category?.[0]?.description ||
								`"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua`}
						</p>
						<img
							src={state?.category?.[0]?.image?.url || Img}
							alt=""
							className="tw-mt-6"
						/>
						<div className="tw-mt-8 tw-space-y-5">
							<DetailsCard>
								<div>
									{" "}
									<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
										Description
									</h4>
									<p
										className="tw-text-sm tw-font-normal tw-text-primary satoshi"
										dangerouslySetInnerHTML={createMarkup(state?.bio || "")}
									/>
								</div>
							</DetailsCard>
							{/* <DetailsCard>
								<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
									Skill and expertise
								</h4>
								<strong className="tw-text-base tw-font-bold tw-text-primary satoshi">
									Music
								</strong>
							</DetailsCard> */}
							<DetailsCard>
								<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
									Preferences and interests
								</h4>
								<div className="tw-flex tw-gap-5 tw-items-center">
									{state?.interests?.map((it, i) => (
										<TagsComp text={it?.title} key={i} />
									))}
								</div>
							</DetailsCard>
							{/* <DetailsCard>
								<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
									Uploaded Certificates
								</h4>
								<div className="tw-flex tw-gap-5 tw-items-center">
									<TagsComp text={"Rock"} />
									<TagsComp text={"Jazz"} />
								</div>
							</DetailsCard>
							<DetailsCard>
								<h4 className="tw-text-xl tw-font-normal tw-text-primary satoshi">
									Uploaded Certificates
								</h4>
								<div className="tw-flex tw-gap-5 tw-items-center">
									<TagsComp text={"Rock"} />
									<TagsComp text={"Jazz"} />
								</div>
							</DetailsCard> */}
						</div>
					</div>
					<div className="tw-mt-16">
						<div className="tw-border-b tw-border-b-[#01346766] tw-pb-4">
							<div>
								{" "}
								<h3 className="tw-text-xl tw-font-bold satoshi tw-text-[#000851]">
									Reviews
								</h3>
								<small className="tw-text-sm tw-font-medium satoshi tw-text-[#000851]">
									34 Ratings
								</small>
							</div>
						</div>
						<div className="tw-mt-8">
							<h3 className="tw-text-xl tw-font-bold satoshi tw-text-[#000851]">
								Reviews
							</h3>
							<div className="tw-space-y-5">
								<div>
									<div className="tw-flex tw-gap-3 tw-items-center">
										<img src={Avatar} alt="" className="" />
										<div>
											<strong className="tw-font-bold satoshi tw-text-sm tw-text-[#000851]">
												Theresa Webb
											</strong>
											<div>
												{" "}
												<small className="tw-text-sm tw-font-normal tw-text-[#000851] satoshi">
													4 May, 2025
												</small>
											</div>
										</div>
									</div>
									<div className="tw-w-full tw-px-4 tw-py-2 tw-mt-6 tw-bg-[#F4FAFF] tw-rounded-xl">
										<p className="tw-text-darkblue tw-font-normal satoshi tw-text-sm">
											Amet minim mollit non deserunt ullamco est sit aliqua
											dolor do amet sint. Velit officia consequat duis enim
											velit mollit. Exercitation veniam consequat sunt nostrud
											amet.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modal === "order" && (
				<OrderModal
					handleClose={() => setModal("")}
					handleModal={() => setModal("done")}
				/>
			)}
			{modal === "done" && <DoneModal handleClose={() => setModal("")} />}
		</section>
	);
};

const DetailsCard = ({ children }) => {
	return (
		<div>
			<div className="tw-rounded-xl tw-border tw-w-full tw-border-[#eee] tw-py-6 tw-px-4">
				{children}
			</div>
		</div>
	);
};

export const TagsComp = ({ text }) => {
	return (
		<div className="tw-h-11 tw-min-w-28 tw-bg-[#F3F5F5] tw-rounded-full tw-border-[0.5px] tw-border-whites tw-px-3 tw-flex tw-justify-between tw-items-center">
			<strong className="tw-text-base tw-font-bold satoshi tw-text-primary">
				{text}
			</strong>
			<div>
				<IoCloseCircleOutline size={20} />
			</div>
		</div>
	);
};

export default ArtistDetails;
