import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Order from "../assets/personal.png";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import useResourcesStore from "../data/Stores/resourcesstore";
import { apiCall } from "../data/Stores/AuthStore";
import { LoadMore, MainPaginate } from "../components/Pagination";
import { createMarkup } from "./edit-profile";



export const MainPersonal = () => {
	const [datum, setDatum] = useState(null),
		{ data, getResources } = useResourcesStore();

	useEffect(() => {
		setDatum(data);
	}, [data]);

	useEffect(() => {
		apiCall("get", `/api/v1/article`, null, getResources);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[search] = useState(""),
		[loading, setLoading] = useState(null),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/article?limit=${datum?.limit * datum?.nextPage}${
				search ? `&search=${search}` : ""
			}`,
			null,
			getResources
		);
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-pt-8">
				<Header bg={"#002F19"} />
				<div className="tw-py-10 tw-bg-white container tw-mb-10">
					<h1 className="lg:tw-text-xm tw-text-xl tw-text-primary satoshi">
						Best Read Articles
					</h1>
					<div className="tw-mt-16 tw-grid lg:tw-grid-cols-2 tw-gap-16">
						{currentItems?.length > 0 && (
							<div>
								<img
									src={currentItems?.[0]?.image?.url || null}
									alt=""
									className="tw-rounded-xl tw-w-full"
								/>
								<h2 className="tw-mt-6 lg:tw-text-xm tw-text-xl tw-font-bold satoshi tw-text-primary">
									{currentItems?.[0]?.title || ``}
								</h2>
								<p
									className="tw-mt-4 tw-text-base tw-font-normal tw-text-primary satoshi tw-line-clamp-6"
									dangerouslySetInnerHTML={createMarkup(
										currentItems?.[0]?.content || ""
									)}
								/>
								<div className="tw-mt-5">
									<ReadMore item={currentItems?.[0]} />
								</div>
							</div>
						)}
						{currentItems?.length > 1 && (
							<div className="tw-space-y-6">
								{currentItems?.slice(1, 4)?.map((it, i) => (
									<div
										key={i}
										className="tw-flex tw-gap-5 tw-flex-col lg:tw-flex-row lg:tw-items-center">
										<img
											src={it?.image?.url || Order}
											alt=""
											className="tw-w-60 tw-rounded-xl tw-h-36"
										/>
										<div>
											<strong className="tw-text-xl tw-font-bold tw-text-primary satoshi">
												{it?.title}
											</strong>
											<div>
												<small
													className="tw-line-clamp-5 tw-mt-4 tw-text-base tw-font-normal tw-text-primary satoshi"
													dangerouslySetInnerHTML={createMarkup(
														it?.content || ""
													)}
												/>
											</div>
											<div className="tw-mt-3">
												<ReadMore item={it} />
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
					{currentItems?.length > 4 && (
						<>
							<div className="tw-mt-10 tw-pb-2 tw-border-b tw-border-b-black">
								<h5 className="tw-text-xm tw-font-bold satoshi tw-text-primary">
									Others
								</h5>
							</div>
							<div className="tw-grid lg:tw-grid-cols-3 tw-mt-5 tw-gap-6">
								{currentItems?.slice(4)?.map((it, i) => (
									<div key={i} className="">
										<img
											src={it?.image?.url || Order}
											alt=""
											className="tw-rounded-xl"
										/>
										<div className="tw-mt-4">
											<strong className="tw-text-xl tw-font-bold tw-text-primary satoshi">
												{it?.title}
											</strong>
											<div>
												<small
													className="tw-mt-4 tw-text-base tw-font-normal tw-text-primary satoshi tw-line-clamp-3"
													dangerouslySetInnerHTML={createMarkup(
														it?.content || ""
													)}
												/>
											</div>
										</div>
										<ReadMore item={it} />
									</div>
								))}
							</div>
						</>
					)}
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

const Personal = () => <MainPersonal />;

const ReadMore = ({ item }) => {
	const navigate = useNavigate();
	return (
		<div>
			<strong
				onClick={() => navigate("/personal-details", { state: item })}
				className="tw-text-base tw-font-medium tw-text-[#007E33] tw-border-b-2 tw-border-b-[#007E33] tw-cursor-pointer satoshi">
				Read More
			</strong>
		</div>
	);
};

export default Personal;
