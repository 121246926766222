import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";

const IndexTalent = () => {
  const navigate = useNavigate();

  let { isAuth } = useAuthStore();
	useEffect(() => {
		if (isAuth) {
			navigate("/talent-home");
		}
	}, [navigate, isAuth]);
  return <></>;
};

export default IndexTalent;
