import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import { SelectInput } from "../../components/inputs";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import useCategoryStore from "../../data/Stores/category";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import Talent from "../../assets/designers2.png";

const Preferences = () => {
	return (
		<div>
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		items = useLocation()?.state,
		{settings}=useAuthStore(),
		handleSubmit = e => {
			e?.preventDefault();
			if (!state?.category) return;

			navigate("/talent-register/interests", { state });
		};

	useEffect(() => {
		if (items) setState({ ...state, ...items });
		else navigate(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	let [datum, setDatum] = useState(null),
		{ data, getCategory } = useCategoryStore();
	useEffect(() => {
		setDatum(data);
	}, [data]);

	useEffect(() => {
		apiCall("get", "/api/v1/category?pagination=not", null, getCategory);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
			<div
				className="tw-w-full tw-h-full login2"
				style={{
					backgroundImage: `url(${
						settings?.registerImage?.url || Talent
					})`,
				}}></div>
			<div className="lg:tw-w-full tw-w-11/12 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
				<div>
					<strong className="tw-text-4xl bolder satoshi tw-text-primary">
						<span
							className="tw-me-3 tw-inline-flex tw-cursor-pointer"
							onClick={() => {
								navigate("/talent-register/skills", { state });
							}}>
							<IoArrowUndoCircleOutline size={24} />
						</span>
						Preferences
					</strong>
					<form onSubmit={handleSubmit} action="" className="tw-mt-8">
						<SelectInput
							label={"Select your category"}
							options={datum?.docs}
							value={state?.category}
							onChange={textChange}
							name="category"
						/>
						<div className="tw-mt-10">
							<button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
								Next
							</button>
							<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
								Already have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-underline tw-cursor-pointer">
									Sign In
								</span>
							</h6>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Preferences;
