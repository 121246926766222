import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import About from "../assets/about.png";
import { Features, Supported, Testimonial } from "./home";

const AboutUs = () => {
  return (
    <div>
      <section className="tw-bg-white tw-min-h-screen tw-w-full tw-pt-12">
        <Header bg={"#002F19"} />
        <MainSection />
        <Features />
        <Testimonial />
        <Footer />
      </section>
    </div>
  );
};

const MainSection = () => {
  return (
    <div>
      <div className="tw-my-16 container">
        <div className="tw-grid lg:tw-grid-cols-2 lg:tw-items-center">
          <div>
            <strong className="tw-text-xm bolder tw-max-w-sm tw-text-primary">
              Supercharge your project with locally sourced global talents.
            </strong>
          </div>
          <div className="tw-w-full">
            <div className="tw-max-w-md tw-float-right">
              <small className="tw-text-base tw-text-primary satoshi tw-font-normal">
                Soundmap is the comprehensive platform for finding Africa’s best
                music talent with ease. Our easy-to-navigate platform takes the
                hassle out of finding quality talent, so you can focus on making
                the best of your music project. Find the best musicians,
                songwriters, backup vocalists and more on a platform that makes
                it easy to bring your project to life.
              </small>
            </div>
          </div>
        </div>
        <div className="tw-mt-14">
          <img src={About} alt="" className="tw-w-full" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
