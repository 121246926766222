import React, { useState, useEffect } from "react";
import ModalContainer from "./modalcontainer";
import Textinput, { SelectInput } from "../inputs";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/Stores/AuthStore";
import useOrdersStore from "../../data/Stores/orderstore";
import { MainBtn } from "../button";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import {
// 	FaRegArrowAltCircleLeft,
// 	FaRegArrowAltCircleRight,
// 	FaPlusCircle,
// 	FaTrashAlt,
// } from "react-icons/fa";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import { SchedulerClient } from "../../talent/availability";

const OrderModal = ({ handleClose, handleModal }) => {
	const init = {},
		items = useLocation()?.state,
		[user, setUser] = useState(null),
		initDate = { date: moment().format("MM/DD/YYYY") },
		[tab, setTab] = useState(0),
		[data, setData] = useState([initDate]),
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ addOrders } = useOrdersStore();
	let handleSubmit = async e => {
			e?.preventDefault();
			let errArr2 = [];
			if (!state?.title) errArr2?.push("Order title is required");
			if (!state?.date) errArr2?.push("Date you need this talent is required");
			if (!state?.duration) errArr2?.push("Duration required");
			if (!state?.durationType) errArr2?.push("Duration Type is required");
			if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
			setLoading(true);
			let newState = { ...state };
			newState = {
				...newState,
				creative: items?._id,
				category: items?.category?.[0]?._id,
			};
			if (
				state?.durationType === "hour" ||
				(state?.durationType === "day" && Number(state?.duration) <= 1)
			) {
			} else {
				let newDays = [];
				for (let d = 0; d < data.length; d++) {
					if (data?.[d]?.show && data?.[d]?.show2)
						newDays?.push({ day: data?.[d]?.day, time: data?.[d]?.time });
				}
				newState = { ...newState, availability: newDays };
			}
			console.log({ newState, data });
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/order`,
				newState
			);
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				addOrders(response);
				handleModal();
				return;
			}
			setLoading(false);
		},
		preSubmit = async e => {
			e?.preventDefault();
			let errArr2 = [];
			if (!state?.title) errArr2?.push("Order title is required");
			if (tab !== 0)
				if (!state?.date)
					errArr2?.push("Date you need this talent is required");
			if (!state?.duration) errArr2?.push("Duration required");
			if (!state?.durationType) errArr2?.push("Duration Type is required");
			if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
			//
			if (tab === 1) {
				if (["hour", "day"]?.includes(state?.durationType) && tab === 0) {
					if (state?.durationType === "day") {
						if (state?.duration <= 7) {
							if (data?.length > state?.duration)
								return toast.info(
									`Please select a maximum of ${state?.duration} ${state?.durationType} date&time`
								);
							handleSubmit(e);
						} else {
							if (data?.length === 1) {
								if (!data?.[0]?.date || !state?.[0]?.time)
									return toast.info("Please select a date&time");
							}
							handleSubmit(e);
						}
					} else if (state?.durationType === "hour") {
						console.log({state, data}, "hour");
						if (!state?.date && !state?.time)
							return toast.info("Please select a single date&time");
						handleSubmit(e);
					} else {
						console.log({state, data}, "two");
						if (data?.length === 1) {
							if (!data?.[0]?.date || !data?.[0]?.time)
								return toast.info("Please select a date&time");
						}
						handleSubmit(e);
					}
				} else {
					if (["hour", "day"]?.includes(state?.durationType) && tab === 1){
					console.log({ state, data }, "hour");
					if (!state?.date)
						return toast.info("Please select a  date");
					}else if (data?.length === 1) {
							console.log({ state, data }, "iono");
							if (!data?.[0]?.date || !data?.[0]?.time)
								return toast.info("Please select a date&time");
						}
					handleSubmit(e);
				}
			} else {
				setTab(1);
			}
		};

	// const handleDeleteRowForMutipleItem = () => {
	// 	setData(prevRows => {
	// 		const newRows = [...prevRows];
	// 		newRows.splice(active, 1);
	// 		return newRows;
	// 	});
	// };
	// const addRowForMutipleItem = () => {
	// 	const newRow = initDate;
	// 	setData([...data, newRow]);
	// };

	useEffect(() => {
		if (items) setUser(items.availability?.times);
	}, [items]);

	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="">
					{" "}
					<h2 className="tw-text-base tw-font-[900] satoshi tw-text-primary">
						{tab > 0 ? `Select date and time` : `Provide more information`}
					</h2>
					<form onSubmit={preSubmit} action="" className="tw-mt-4">
						{tab === 0 && (
							<div className="tw-space-y-3">
								<Textinput
									label={"Event Title"}
									onChange={textChange}
									value={state?.title}
									name="title"
									placeholder={"Birthday Party"}
								/>
								{/* <Textinput
									label={"Date you need this talent"}
									onChange={textChange}
									value={state?.date}
									name="date"
									type="date"
									min={moment().format("YYYY-MM-DD")}
								/> */}
								<SelectInput
									label={"Select Duration Type"}
									options={[
										{ _id: "hour", title: "hours" },
										{ _id: "day", title: "days" },
										{ _id: "week", title: "weeks" },
										{ _id: "month", title: "months" },
										{ _id: "year", title: "years" },
									]}
									value={state?.durationType}
									onChange={textChange}
									name="durationType"
								/>
								<Textinput
									label={"Duration"}
									onChange={textChange}
									value={state?.duration}
									name="duration"
									type="number"
									min={0}
								/>
								{/* {state?.durationType === "hour" ||
								(state?.durationType === "day" &&
									Number(state?.duration) <= 1) ? (
									<Textinput
										label={"Time of day"}
										onChange={textChange}
										value={state?.time}
										name="time"
										type="time"
									/>
								) : null} */}
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Location
										</small>
									</div>
									<textarea
										className="tw-w-full tw-h-28 tw-rounded-2xl tw-bg-[#F3F5F5] tw-text-base tw-p-4 tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										style={{ resize: "none", height: "10rem" }}
										onChange={textChange}
										value={state?.location}
										name="location"
										rows="10"
									/>
								</div>
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Additional information about this request
										</small>
									</div>
									<textarea
										className="tw-w-full tw-p-4 tw-h-28 tw-rounded-2xl tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										style={{ resize: "none", height: "10rem" }}
										onChange={textChange}
										value={state?.additionalInformation}
										name="additionalInformation"
										rows="10"
									/>
								</div>
							</div>
						)}
						{tab === 1 && (
							<>
								<span
									className="tw-me-3 tw-inline-flex tw-cursor-pointer"
									onClick={() => {
										setTab(0);
									}}>
									<IoArrowUndoCircleOutline size={24} /> Back
								</span>

								{state?.durationType === "hour" ||
								(state?.durationType === "day" &&
									Number(state?.duration) <= 1) ? (
									<>
										<DateSelectionArr
											user={user}
											setData={setState}
											item={state}
										/>
									</>
								) : (
									<>
										<div className="tw-space-y-3">
											<Textinput
												label={"Stating date you need this talent"}
												onChange={textChange}
												value={state?.date}
												name="date"
												type="date"
												min={moment().format("YYYY-MM-DD")}
											/>
										</div>
										{state?.date && (
											<>
												<PreScheduler2 user={user} setData={setData} />
												{/* {data?.length > 1 && (
													<div className="tw-flex tw-justify-between tw-items-center tw-my-2">
														{active !== 0 && (
															<span
																className="tw-flex tw-items-center tw-cursor-pointer"
																onClick={() => setActive(ac => ac--)}>
																{" "}
																<FaRegArrowAltCircleLeft /> Prev
															</span>
														)}
														{active < data?.length - 1 && (
															<span
																className="tw-flex tw-items-center tw-cursor-pointer"
																onClick={() => setActive(ac => ac++)}>
																{" "}
																<FaRegArrowAltCircleRight /> Next
															</span>
														)}
													</div>
												)}
												<div className="tw-flex tw-justify-between tw-items-center tw-my-2">
													{data?.length > 1 && (
														<>
															<span
																className="tw-flex tw-items-center tw-cursor-pointer"
																onClick={handleDeleteRowForMutipleItem}>
																{" "}
																<FaTrashAlt /> Delete Current Date
															</span>
														</>
													)}
													<span
														className="tw-flex tw-items-center tw-cursor-pointer"
														onClick={addRowForMutipleItem}>
														{" "}
														<FaPlusCircle /> Add New Date
													</span>
												</div> */}
											</>
										)}
									</>
								)}
							</>
						)}
						<div className="tw-mt-8">
							<MainBtn
								loading={loading}
								onClick={preSubmit}
								text={`Proceed`}
								css="tw-capitalize tw-w-full tw-rounded-xl tw-text-sm tw-font-semibold satoshi tw-bg-[#59FE9C] tw-text-darkblue"
								width="tw-w-full"
								height="tw-h-12"
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default OrderModal;

export const CalenderComponent = ({ css, tileDisabled, onClickDay, date }) => {
	return (
		<>
			<div className="block w-full">
				{/* {moment(date || moment()).format("MMMM")} */}
				<Calendar
					className={css}
					showWeekNumbers={false}
					calendarType="US"
					minDate={new Date()}
					tileDisabled={tileDisabled}
					onClickDay={onClickDay}
					// showNavigation={false}
				/>
			</div>
		</>
	);
};

export const DateSelectionArr = ({ user, setData, item }) => {
	let [avail, setAvail] = useState(null),
		isDisabled = ({ date }) => {
			let userDays = user,
				disDays = [];
			let findSunday = userDays?.find(li => li?.day === "Sunday");
			if (!findSunday) disDays?.push(0);
			else {
				if (!findSunday?.show || findSunday?.available?.length === 0)
					disDays?.push(0);
			}
			let findMonday = userDays?.find(li => li?.day === "Monday");
			if (!findMonday) disDays?.push(1);
			else {
				if (!findMonday?.show || findMonday?.available?.length === 0)
					disDays?.push(1);
			}
			let findTuesday = userDays?.find(li => li?.day === "Tuesday");
			if (!findTuesday) disDays?.push(2);
			else {
				if (!findTuesday?.show || findTuesday?.available?.length === 0)
					disDays?.push(2);
			}
			let findWednesday = userDays?.find(li => li?.day === "Wednesday");
			if (!findWednesday) disDays?.push(3);
			else {
				if (!findWednesday?.show || findWednesday?.available?.length === 0)
					disDays?.push(3);
			}
			let findThursday = userDays?.find(li => li?.day === "Thursday");
			if (!findThursday) disDays?.push(4);
			else {
				if (!findThursday?.show || findThursday?.available?.length === 0)
					disDays?.push(4);
			}
			let findFriday = userDays?.find(li => li?.day === "Friday");
			if (!findFriday) disDays?.push(5);
			else {
				if (!findFriday?.show || findFriday?.available?.length === 0)
					disDays?.push(5);
			}
			let findSaturday = userDays?.find(li => li?.day === "Saturday");
			if (!findSaturday) disDays?.push(6);
			else {
				if (!findSaturday?.show || findSaturday?.available?.length === 0)
					disDays?.push(6);
			}

			return disDays?.includes(date?.getDay());
		},
		onClickDay = (value, event) => {
			// let day = moment(value).day();
			let day2 = moment(value).format("dddd");
			// setDate(moment(value).format("dddd, MMMM DD"));
			let findDay = user?.find(li => li?.day === day2);
			console.log({ findDay, day2 });
			setAvail(findDay?.sortedTime);
			setData(prevRows => {
				const newRows = { ...prevRows };
				// console.log({ newRows });
				newRows.date = moment(value).format("YYYY-MM-DD");
				return newRows;
			});
		};

	return (
		<>
			<p className="tw-fw-semibold tw-text-2xl tw-hidden">
				Select a date and time
			</p>
			<CalenderComponent
				css={"tw-rounded tw-p-3 tw-text-dark tw-w-full tw-mx-auto tw-my-3"}
				tileDisabled={isDisabled}
				onClickDay={onClickDay}
				date={item?.date}
			/>
			<h3 className="tw-bold tw-fw-bold">
				{moment(item?.date).format("dddd, MMMM DD")}
			</h3>
			{avail && avail?.length > 0 && (
				<div className="tw-grid-cols-3 tw-grid tw-gap-3">
					{avail
						?.filter(list => !list?.picked?.includes(item?.date))
						?.map((list, i) => (
							<button
								onClick={() => {
									setData(prevRows => {
										const newRows = { ...prevRows };
										newRows["time"] = list?.start;
										return newRows;
									});
								}}
								type="button"
								className={`tw-px-4 tw-py-2 tw-rounded-md tw-border tw-border-plain tw-text-xs tw-whitespace-nowrap hover:tw-bg-plain hover:tw-text-white tw-w-full ${
									item?.time === list?.start ? "tw-text-white tw-bg-plain" : ""
								}`}
								key={i}>
								{moment(list?.start, "HH:mm:ss").format("LT")}
							</button>
						))}
				</div>
			)}
		</>
	);
};

export const PreScheduler2 = ({ user, setData }) => {
	let [MondayShow, setMondayShow] = useState(true),
		[TuesdayShow, setTuesdayShow] = useState(true),
		[WednesdayShow, setWednesdayShow] = useState(true),
		[ThursdayShow, setThursdayShow] = useState(true),
		[FridayShow, setFridayShow] = useState(true),
		[SaturdayShow, setSaturdayShow] = useState(true),
		[SundayShow, setSundayShow] = useState(true),
		[Monday, setMonday] = useState(""),
		[Tuesday, setTuesday] = useState(""),
		[Wednesday, setWednesday] = useState(""),
		[Thursday, setThursday] = useState(""),
		[Friday, setFriday] = useState(""),
		[Saturday, setSaturday] = useState(""),
		[Sunday, setSunday] = useState(""),
		[days, setDays] = useState(null);

	useEffect(() => {
		let newArr = [];
		for (let u = 0; u < user.length; u++) {
			let element = user[u];

			if (element?.day === "Monday")
				element = { ...element, show2: MondayShow };
			if (element?.day === "Tuesday")
				element = { ...element, show2: TuesdayShow };
			if (element?.day === "Wednesday")
				element = { ...element, show2: WednesdayShow };
			if (element?.day === "Thursday")
				element = { ...element, show2: ThursdayShow };
			if (element?.day === "Friday")
				element = { ...element, show2: FridayShow };
			if (element?.day === "Saturday")
				element = { ...element, show2: SaturdayShow };
			if (element?.day === "Sunday")
				element = { ...element, show2: SundayShow };

			newArr?.push({ ...element });
		}
		setDays(newArr);
	}, [
		FridayShow,
		MondayShow,
		SaturdayShow,
		SundayShow,
		ThursdayShow,
		TuesdayShow,
		WednesdayShow,
		user,
	]);

	useEffect(() => {
		if (days) {
			let newDays = [];
			for (let d = 0; d < days?.length; d++) {
				let element = days?.[d];

				if (element?.day === "Monday") element = { ...element, time: Monday };
				if (element?.day === "Tuesday") element = { ...element, time: Tuesday };
				if (element?.day === "Wednesday")
					element = { ...element, time: Wednesday };
				if (element?.day === "Thursday")
					element = { ...element, time: Thursday };
				if (element?.day === "Friday") element = { ...element, time: Friday };
				if (element?.day === "Saturday")
					element = { ...element, time: Saturday };
				if (element?.day === "Sunday") element = { ...element, time: Sunday };

				if (!element?.show2) element = { ...element, available: false };
				newDays?.push({ ...element });
			}
			setData(newDays);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [days, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday]);

	if (!days) return <></>;

	return (
		<div className="tw-py-5">
			{days
				?.filter(it => it?.show)
				.map((day, idx) => (
					<SchedulerClient
						day={day?.day}
						time={day?.available}
						key={idx}
						show={day?.show2}
						setMonday={setMonday}
						setThursday={setThursday}
						setTuesday={setTuesday}
						setWednesday={setWednesday}
						setFriday={setFriday}
						setSaturday={setSaturday}
						setSunday={setSunday}
						Monday={Monday}
						Thursday={Thursday}
						Tuesday={Tuesday}
						Wednesday={Wednesday}
						Friday={Friday}
						Saturday={Saturday}
						Sunday={Sunday}
						setMondayShow={setMondayShow}
						setThursdayShow={setThursdayShow}
						setTuesdayShow={setTuesdayShow}
						setWednesdayShow={setWednesdayShow}
						setFridayShow={setFridayShow}
						setSaturdayShow={setSaturdayShow}
						setSundayShow={setSundayShow}
						sortedTime={day?.sortedTime}
					/>
				))}
		</div>
	);
};
