import React, { useState, useEffect } from "react";
// import { HeroSection, Portfolio, Subscribe } from "../screens/home";
// import Footer from "../components/footer";
import NewOrderModal from "../components/modals/newordermodal";
import DeclineIntentModal from "../components/modals/declineintentmodal";
import { DoneModalAccept } from "../components/modals/donemodal";
// import { useNavigate } from "react-router-dom";
import useOrdersStore from "../data/Stores/orderstore";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../data/Stores/AuthStore";
import Home from "../screens/hometwo";

const TalentHome = () => {
  const [modal, setModal] = useState("");
  // const navigate = useNavigate();
  const { homepending, getHomePendingOrders, deleteOrders } = useOrdersStore(),
    [reason, setReason] = useState(""),
    [loading, setLoading] = useState(null),
    { returnErrors } = useErrorStore();

  let handleSubmit = async (e) => {
    e?.preventDefault();
    if (modal?.includes("decline"))
      if (!reason) return toast.info("Reason is required");
    setLoading(true);
    let newState = {
      order: homepending?.docs?.[0]?._id,
      creativeResponse: modal?.includes("decline") ? "declined" : "approved",
    };
    if (reason)
      newState = {
        ...newState,
        creativeReason: reason,
      };
    let { response, errArr, errMsg } = await apiCall(
      "put",
      `/api/v1/order/creative-response`,
      newState
    );
    // console.log({ response, errArr, errMsg });
    if (errArr) {
      setLoading(false);
      return returnErrors(errArr);
    }
    if (errMsg) {
      setLoading(false);
      return toast.error(errMsg);
    }
    setLoading(false);
    if (response) {
      deleteOrders(response);
      setModal(modal?.includes("decline") ? "done" : "accept");
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (homepending?.totalDocs > 0) setModal("new-order");
  }, [homepending]);

  useEffect(() => {
    apiCall(
      "get",
      "/api/v1/order?status=pending&homepage=homepage",
      null,
      getHomePendingOrders
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Home />
      {modal === "new-order" && (
        <NewOrderModal
          handleClose={() => setModal("")}
          handleDecline={() => setModal("decline")}
          handleAccept={handleSubmit}
          order={homepending?.docs?.[0]}
          loading={loading}
        />
      )}
      {modal === "decline" && (
        <DeclineIntentModal
          handleClose={() => setModal("")}
          handleDone={handleSubmit}
          order={homepending?.docs?.[0]}
          loading={loading}
          setReason={setReason}
          reason={reason}
        />
      )}
      {modal === "done" && (
        <DoneModalAccept
          type={"decline"}
          handleClose={() => setModal("")}
          order={homepending?.docs?.[0]}
        />
      )}
      {modal === "accept" && (
        <DoneModalAccept
          handleClose={() => setModal("")}
          type={"accept"}
          order={homepending?.docs?.[0]}
        />
      )}
    </div>
  );
};

export default TalentHome;
