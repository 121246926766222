import React from "react";
import Img from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        onClick={() => navigate("/")}
        src={Img}
        alt=""
        className="tw-cursor-pointer"
      />
    </div>
  );
};

export default Logo;
