import React from "react";
import ModalContainer from "./modalcontainer";
import PrimaryBtn from "../button";
import { CheckList } from "../../pages/orders";

const NewOrderModal = ({
	handleClose,
	handleAccept,
	handleDecline,
	order,
	loading,
}) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div>
					<h2 className="tw-text-xm tw-font-[900] satoshi tw-text-primary">
						New Orders
					</h2>
					<div className="tw-p-3 tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D]">
						<strong className="tw-text-base tw-font-medium satoshi tw-text-primary">
							{order?.title}
						</strong>
						<div>
							<small className="tw-text-xs tw-font-normal satoshi tw-text-primary">
								{order?.additionalInformation}
							</small>
						</div>
					</div>
					<CheckList order={order} />
					<div className="tw-p-3 tw-mt-5 tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D]">
						<strong className="tw-text-base tw-font-medium satoshi tw-text-primary">
							Address
						</strong>
						<div>
							<small className="tw-text-xs tw-font-normal satoshi tw-text-primary">
								{order?.location}
							</small>
						</div>
					</div>
					<div className="tw-mt-8 tw-flex tw-justify-end">
						<div className="tw-flex tw-gap-5 tw-items-center">
							<PrimaryBtn
								onClick={handleDecline}
								bg={"white"}
								text={"Decline"}
								color={"#002F19"}
							/>
							<PrimaryBtn
								onClick={handleAccept}
								bg={"#59FE9C"}
								text={"Accept"}
								color={"#002F19"}
								loading={loading}
							/>
						</div>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default NewOrderModal;
