import React, { useEffect, useState, useRef, useContext } from "react";
import { io } from "socket.io-client";
import { useURL } from "./data/Config";
import useAuthStore, { apiCall } from "./data/Stores/AuthStore";
import useSocketStore from "./data/Stores/socketstore";
import Notify from "./assets/notify.wav";
import { useLocation } from "react-router-dom";
import { GlobalState } from "./data/Context";

const SocketClient = () => {
	const { user } = useAuthStore(),
		{
			getActive,
			getNewMessage,
			getUpdateMessage,
			getChats,
			// getSocket
		} = useSocketStore(),
		{ socket, setSocket } = useContext(GlobalState);

	let [socketNew, setNewSocket] = useState(null),
		audioRef = useRef(),
		{ state, pathname } = useLocation();

	const setUpSocket = async () => {
		var newSocket = io(useURL, {
			query: {
				userId: user?._id,
			},
			transports: ["websocket"],
		});
		newSocket.on("disconnect", () => {
			setNewSocket(null);
			setTimeout(setUpSocket, 3000);
		});
		setNewSocket(newSocket);
		// console.log({ ss: newSocket });
	};

	useEffect(() => {
		setUpSocket();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log({ s: socketNew });
	useEffect(() => {
		if (socketNew) {
			setSocket(socketNew);
			// apiCall("get", "/api/v1/chat/manage-chat", null, getChats);
			// getSocket(socketNew);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketNew]);

	useEffect(() => {
		if (socket)
			socket?.on("get-online-users", active => {
				console.log({ active });
				getActive(active);
			});
		// console.log({ socketNew });
		return () => {
			if (socket) socket?.off("get-online-users");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	useEffect(() => {
		if (socket)
			socket?.on("newMessage", newMessage => {
				console.log({ newMessage });
				let audio1 = audioRef?.current;
				if (
					pathname?.includes("/chat") &&
					state?.chat &&
					state?.chat === newMessage?.chat
				) {
					getNewMessage(newMessage);
					if (audio1.paused) {
						audio1.play().catch(error => {
							// Handle play error, if necessary
							console.error("Error playing audio:", error);
						});
					}
					apiCall("get", "/api/v1/chat/manage-chat", null, getChats);
				} else {
					apiCall("get", "/api/v1/chat/manage-chat", null, getChats);
					if (audio1.paused) {
						audio1.play().catch(error => {
							// Handle play error, if necessary
							console.error("Error playing audio:", error);
						});
					}
				}
			});
		// console.log({ socketNew });
		return () => {
			if (socket) socket?.off("newMessage");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	useEffect(() => {
		if (socket)
			socket?.on("updateMessage", updateMessage => {
				console.log({ updateMessage });
				getUpdateMessage(updateMessage);
			});
		// console.log({ socketNew });
		return () => {
			if (socket) socket?.off("updateMessage");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	// console.log({ socket });
	// JoinUser
	useEffect(() => {
		socketNew?.emit("checkUserOnlineAdmin");
		socketNew?.emit("checkUserOnline");
	}, [socketNew, user]);

	return (
		<>
			<audio
				controls
				ref={audioRef}
				className="notificationSound"
				style={{ display: "none" }}>
				<source src={Notify} type="audio/mp3" />
			</audio>
		</>
	);
};

export default SocketClient;
