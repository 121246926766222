import React, { useEffect } from "react";
import Header from "../components/header";
// import Img from "../assets/personal-details.png";
import Footer from "../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { createMarkup } from "./edit-profile";
import { apiCall } from "../data/Stores/AuthStore";

const PersonalDetails = () => {
	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-pt-8">
				<Header bg={"#002F19"} />
				<MainSection />
				<Footer />
			</div>
		</div>
	);
};

const MainSection = () => {
	let { state } = useLocation(),
		navigate = useNavigate();

	useEffect(() => {
		if (!state) navigate(-1);
		else {
			apiCall("patch", `/api/v1/article?article=${state?._id}`);
		}
	}, [state, navigate]);

	return (
		<section className="container tw-bg-white tw-py-16">
			<h1 className="bold tw-text-xm tw-text-primary">{state?.title}</h1>
			<img
				src={state?.image?.url}
				alt=""
				className="tw-h-40 lg:tw-h-full tw-w-full tw-rounded-md"
			/>
			<div className="tw-mt-10">
				<h2 className="bold tw-text-xm tw-text-primary">{state?.title}</h2>
				<p
					className="tw-mt-6 tw-text-base tw-font-normal satoshi tw-text-primary"
					dangerouslySetInnerHTML={createMarkup(state?.content || "")}
				/>
			</div>
		</section>
	);
};

export default PersonalDetails;
