import React, { useState } from "react";
import PrimaryBtn from "../components/button";
import Header from "../components/header";
import AboutImg from "../assets/abouthero.png";
import F1 from "../assets/features1.png";
import F2 from "../assets/features2.png";
import F3 from "../assets/features3.png";
import F4 from "../assets/featires4.png";
import S1 from "../assets/support1.png";
import PortImg from "../assets/portfolio.png";
import { FaCircleChevronRight } from "react-icons/fa6";
import Partner1 from "../assets/partner1.svg";
import Partner2 from "../assets/partner2.svg";
import Partner3 from "../assets/partner3.svg";
import Partner4 from "../assets/partner24.svg";
import Avatar from "../assets/36.svg";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import YouTubeModalPlayer from "../components/youtubemodalplayer";
import { PlayCircle } from "lucide-react";

const Home2 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeroSection
        text={"Find Africa’s best music talent with ease."}
        desc={
          "Soundmap is the all-in-one platform for finding the best African talents for your music project. From musicians and songwriters to backup vocalists and instrumentalists, we bring the best talent to you."
        }
        btn_text={"Get Started"}
        onClick={() => navigate("/login")}
      />
      <AboutUs />
      <Features />
      <Portfolio />
      <Partners />
      <Testimonial />
      <Subscribe />
      <Footer />
    </div>
  );
};

export const HeroSection = ({ text, desc, btn_text, onClick }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const toggleVideoModal = () => {
    setIsVideoOpen(!isVideoOpen);
  };
  return (
    <div>
      <div
        onClick={toggleVideoModal}
        className="tw-w-full tw-relative tw-h-screen hero"
      >
        <div className="tw-absolute tw-top-7 tw-w-full">
          <Header bg={"#002F19"} />
        </div>
        <div className="hero2 tw-h-full tw-w-full">
          <div className="container tw-flex tw-items-center tw-h-full">
            <div data-aos="fade-in" data-aos-duration="1500" className="">
              <h1 className="lg:tw-text-[42px] bolder tw-leading-[57px] tw-max-w-lg tw-text-4xl tw-text-white satoshi">
                {text}
              </h1>
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                className="tw-pt-1 tw-text-xl tw-font-normal satoshi tw-text-white tw-max-w-md"
              >
                {desc}
              </p>
              <div className="tw-mt-8 tw-z-[1000] tw-flex tw-gap-6">
                <PrimaryBtn
                  onClick={onClick}
                  color={"#010031"}
                  bg={"#59FE9C"}
                  text={btn_text}
                />
                {/* <button
                  onClick={toggleVideoModal}
                  className="tw-flex tw-items-center tw-space-x-2 tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg tw-hover:bg-red-700 tw-transition-colors"
                >
                  <PlayCircle size={24} />
                  <span>Play Video</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <YouTubeModalPlayer
          videoUrl={"https://youtu.be/J2Tpldrady0"}
          isOpen={isVideoOpen}
          toggleOpen={toggleVideoModal}
        />
      </div>
    </div>
  );
};

export const AboutUs = () => {
  return (
    <section className="tw-w-full tw-bg-white tw-py-20">
      <div className="container tw-p-10 tw-bg-[#F0FAFF] tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-center tw-gap-12 lg:tw-h-full">
        <div>
          <img
            data-aos="zoom-up"
            data-aos-duration="1500"
            src={AboutImg}
            alt=""
            className="lg:tw-h-80 tw-h-40 tw-rounded-2xl"
          />
        </div>
        <div data-aos="fade-up-left" data-aos-duration="1500">
          <h2 className="tw-text-xm bolder tw-text-primary satoshi">
            About SoundMap
          </h2>
          <p className="tw-text-base satoshi tw-max-w-lg tw-font-normal satoshi tw-text-primary">
            Soundmap stands as a link between the service seeker and the service
            providers. We provide and bring you vetted and trusted talents you
            might require in diverse areas of production.
          </p>
        </div>
      </div>
    </section>
  );
};

export const Features = () => {
  const features = [
    {
      title: "Detailed user profiles",
      desc: "Explore the skills, experience, education and certification of talents right from their user profiles. Get all the information you need to make the right decision, before striking a conversation.",
    },
    {
      title: "Talent vetting",
      desc: "Take things a step further with a safe and secure chat with our admins. Learn more about a talent, including past working history and effectiveness before you make a decision.",
    },
    {
      title: "Simplified contracting and deals",
      desc: "Avoid getting buried in complicated paperwork. We handle agreements and contracts between you and the talent, so you can both focus on making the most of your project.",
    },
    {
      title: "Quick talent match",
      desc: "Need the talent to come to you instead? Post your project details, and get matched with freelancers that fit your project description - quick and easy.",
    },
  ];
  return (
    <section className="tw-w-full tw-bg-white lg:tw-py-20 tw-py-10">
      <div className="container">
        <h3
          data-aos="flip-up"
          data-aos-duration="1500"
          className="tw-text-3xl bolder tw-text-center tw-text-primary satoshi"
        >
          Our Key Features
        </h3>
        <p
          data-aos="fade-in"
          data-aos-duration="1500"
          className="tw-text-base tw-text-center tw-font-normal tw-text-primary satoshi"
        >
          Find talented musicians, artists, designers, and writers for your
          projects."
        </p>
        <div className="tw-grid lg:tw-grid-cols-2 tw-gap-5">
          <div className="lg:tw-gap-6 tw-space-y-5 lg:tw-space-y-0 tw-mt-8 lg:tw-grid tw-grid-cols-2">
            {features?.map((it, i) => (
              <div
                data-aos="fade-in"
                data-aos-duration="1500"
                key={i}
                className=""
              >
                <strong className="tw-text-2xl tw-text-primary tw-font-medium satoshi">
                  {i + 1}
                </strong>
                <div>
                  <h4 className="tw-text-xl tw-font-bold tw-text-primary satoshi">
                    {it?.title}
                  </h4>
                  <div>
                    <small className="tw-text-base tw-font-normal tw-text-primary satoshi">
                      {it?.desc}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            <div>
              <img
                data-aos="flip-up"
                data-aos-duration="1500"
                src={F1}
                alt=""
                className=""
              />
              <img
                data-aos="flip-up"
                data-aos-duration="1500"
                src={F2}
                alt=""
                className="tw-mt-6 tw-w-full"
              />
            </div>
            <div className="-;g:tw-mt-10">
              <img
                data-aos="flip-up"
                data-aos-duration="1500"
                src={F3}
                alt=""
                className="tw-w-full"
              />
              <img
                data-aos="flip-up"
                data-aos-duration="1500"
                src={F4}
                alt=""
                className="tw-mt-6 tw-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Supported = () => {
  const Arr = [
    {
      title: "Artist",
      desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ',
    },
    {
      title: "Artist",
      desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ',
    },
    {
      title: "Artist",
      desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ',
    },
    {
      title: "Artist",
      desc: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ',
    },
  ];
  return (
    <section className="tw-w-full tw-bg-white tw-py-20">
      <div className="container">
        <h2
          data-aos="flip-up"
          data-aos-duration="1500"
          className="tw-text-3xl bolder tw-text-center tw-text-primary"
        >
          Creative Supported
        </h2>
        <p
          data-aos="fade-up"
          data-aos-duration="1500"
          className="tw-text-base tw-text-center tw-font-normal tw-text-primary satoshi"
        >
          Find talented musicians, artists, designers, and writers for your
          projects."
        </p>
        <div className="tw-mt-10">
          {Arr?.map((it, i) => (
            <div
              key={i}
              className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-16 lg:tw-items-center tw-py-10 tw-border-b tw-border-black"
            >
              <img
                data-aos="zoom-up"
                data-aos-duration="1500"
                src={S1}
                alt=""
                className=""
              />
              <div data-aos="fade-in" data-aos-duration="1500">
                <h4 className="tw-text-2xl tw-font-bold satoshi tw-text-primary">
                  {it?.title}
                </h4>
                <p className="tw-text-base tw-mt-4 tw-font-normal satoshi tw-text-primary">
                  {it?.desc}
                </p>
                <div className="tw-mt-10">
                  <PrimaryBtn color={"black"} bg={"#59FE9C"} text={"View"} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Portfolio = () => {
  const Arr = [
    {
      title: "Vetted, talented creatives for every project",
      desc: "We curate the best talent across different categories to match your needs for any project.",
    },
    {
      title: "Secure contracts and timely delivery",
      desc: "We simplify negotiation, so you can easily agree on project terms, timelines, and pricing.",
    },
    {
      title: "Easy and effective collaboration",
      desc: "We provide tools and features that enable you and creatives to collaborate effectively on projects.",
    },
  ];
  return (
    <section className="container tw-py-20 tw-bg-white tw-grid lg:tw-grid-cols-2 tw-gap-10 tw-items-center">
      <div>
        <img
          data-aos="zoom-up"
          data-aos-duration="1500"
          src={PortImg}
          alt=""
          className=""
        />
      </div>
      <div className="tw-space-y-5">
        {Arr?.map((it, i) => (
          <div
            data-aos="flip-up"
            data-aos-duration="1500"
            key={i}
            className="tw-bg-[#F0FAFF] tw-h-36 tw-w-full tw-rounded-3xl tw-px-6 tw-flex tw-justify-between  tw-items-center"
          >
            <div>
              <h5 className="lg:tw-text-2xl tw-text-base tw-font-[900] satoshi tw-text-primary">
                {it?.title}
              </h5>
              <p className="lg:tw-text-base tw-text-sm tw-font-medium satoshi tw-max-w-sm tw-text-primary">
                {it?.desc}
              </p>
            </div>
            <div>
              <FaCircleChevronRight size={20} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Partners = () => {
  const partners = [Partner1, Partner2, Partner3, Partner4];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="containr">
        <h3 className="tw-text-4xl bolder tw-text-center tw-text-primary satoshi">
          Our Partners
        </h3>
        <p className="tw-text-center tw-font-normal tw-text-base tw-text-primary satoshi">
          Find talent musicians, artists, designers and writers for your
          projects
        </p>
        <div className="tw-mt-14 tw-flex tw-justify-center tw-gap-6">
          {partners?.map((it, i) => (
            <div key={i} className="">
              <img src={it} alt="" className="" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Testimonial = () => {
  const Arr = [
    {
      desc: "Soundmap has been monumental in finding instrumentalists for my projects. The body of work felt like it needed an extra touch, and with Soundmap, I was able to find the right instrumentalist to give that extra sauce!",
      name: "Seyi Funke",
      title: "Music producer",
    },
    {
      desc: "I first joined Soundmap as a vocalist, but now I’m also a client. The platform has been useful in both ways, for finding people to work with and bringing my personal projects to life.",
      name: "Johnson Odemigie",
      title: "Musician",
    },
    {
      desc: "I’ve always struggled with finding the right clients to collaborate with on Music projects. Soundmap has made it easy for me. A lot of other freelancing platforms don’t feel like the right fit, but here it’s easy to connect with the people that need my skill.",
      name: "Rachel Asekome",
      title: "Songwriter",
    },
  ];
  return (
    <section className="tw-w-full tw-bg-white tw-py-20">
      <div className="container">
        <h3 className="tw-text-3xl tw-text-center bolder tw-text-darkblue satoshi">
          Testimonial
        </h3>
        <p className="tw-text-center tw-font-normal tw-text-base tw-text-darkblue satoshi">
          Hear from people on the map!
        </p>
        <div className="tw-mt-16 tw-grid lg:tw-grid-cols-3 tw-gap-6">
          {Arr?.map((it, i) => (
            <div
              key={i}
              className="tw-border tw-border-[#E2E2E2] tw-h-80 tw-rounded-3xl"
            >
              <div className="tw-h-60 tw-px-6 tw-flex tw-justify-center tw-items-center">
                <small className="tw-text-base tw-font-normal satoshi tw-text-primary">
                  {it?.desc}
                </small>
              </div>
              <div className="tw-h-20 tw-w-full tw-border-t tw-border-t-[#E2E2E2] tw-flex tw-px-6 tw-gap-3 tw-items-center">
                <img src={Avatar} alt="" className="" />
                <div>
                  <strong className="tw-text-base tw-font-medium satoshi tw-text-primary">
                    {it?.name}
                  </strong>
                  <div>
                    <small className="tw-text-xs tw-font-normal tw-text-primary satoshi">
                      {it?.title}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Subscribe = () => {
  return (
    <section className="tw-bg-white tw-py-20 tw-w-full">
      <div className="container tw-border tw-flex tw-justify-center tw-border-[#E2E2E2] tw-py-16">
        <div>
          <h3 className="tw-text-4xl tw-font-[900] satoshi tw-text-center tw-text-primary">
            Subscribe to Our Platfoem
          </h3>
          <p className="tw-text-base tw-text-center tw-text-primary satoshi tw-font-normal">
            Find talented, musicians, artists, designers and ...
          </p>
          <form action="">
            <div>
              <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                Email Address
              </small>
            </div>
            <input
              type="text"
              className="tw-w-full tw-mt-4 tw-h-12 tw-rounded-2xl tw-bg-[#F3F5F5] tw-border-[0.4px] tw-border-white"
            />
            <button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Home2;
