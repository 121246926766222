import axios from "axios";
import { SetAuthToken } from "./Config";
import useAuthStore, { TOKEN, apiCall } from "./Stores/AuthStore";
import { toast } from "react-toastify";
import useErrorStore from "./Stores/errorstore";
import useOrdersStore from "./Stores/orderstore";
import useCategoryStore from "./Stores/category";
import useInterestStore from "./Stores/intereststore";
import useSocketStore from "./Stores/socketstore";
import useNotificationStore from "./Stores/notificationstore";
import usePaymentStore from "./Stores/paymentstore";

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{
			getUser,
			getUserFail,
			getUserLoading,
			setUserPage,
			setBank,
			setAllBank,
			getSettings,
		} = useAuthStore(),
		{
			getOrders,
			getHomePendingOrders,
			getApprovedOrders,
			getCompletedOrders,
			getPendingOrders,
			getOngoingOrders,
		} = useOrdersStore(),
		{ getCategory } = useCategoryStore(),
		{ getInterest } = useInterestStore(),
		{ getNotification } = useNotificationStore(),
		{ getPayment } = usePaymentStore(),
		{ getChats } = useSocketStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(`/api/v1/user`);
				// console.log({ res: res?.data });
				if (!res?.data?.data?.isAdmin) {
					getUser(res.data);
					setUserPage(
						res?.data?.data?.privilege === "client" ? "client" : "talent"
					);
					apiCall(
						"get",
						"/api/v1/order?status=pending&homepage=homepage",
						null,
						getHomePendingOrders
					);
					apiCall("get", "/api/v1/order", null, getOrders);
					apiCall(
						"get",
						"/api/v1/order?status=pending",
						null,
						getPendingOrders
					);
					apiCall(
						"get",
						"/api/v1/order?status=approved",
						null,
						getApprovedOrders
					);
					apiCall(
						"get",
						"/api/v1/order?status=on-going",
						null,
						getOngoingOrders
					);
					apiCall(
						"get",
						"/api/v1/order?status=completed",
						null,
						getCompletedOrders
					);
					apiCall("get", "/api/v1/chat/manage-chat", null, getChats);
					apiCall("get", "/api/v1/user/manage-bank", null, setBank);
					apiCall("get", "/api/v1/user/bank-list", null, setAllBank);
					apiCall("get", `/api/v1/notification`, null, getNotification);
					apiCall("get", `/api/v1/order/payment`, null, getPayment);
				} else {
					getUserFail();
					getErrorText("Unauthorized User, Access denied");
				}
				apiCall("get", "/api/v1/category?pagination=not", null, getCategory);
				apiCall(
					"get",
					"/api/v1/category/interest?pagination=not",
					null,
					getInterest
				);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
			apiCall("get", "/api/v1/settings", null, getSettings);
		};
	return { loadUser };
};

export default useGenFetcher;
