import React, { useState, useEffect } from "react";
import { DoneModalAccept } from "./modals/donemodal";
import DeclineIntentModal from "./modals/declineintentmodal";
import { apiCall } from "../data/Stores/AuthStore";
import { toast } from "react-toastify";
import useErrorStore from "../data/Stores/errorstore";
import useOrdersStore from "../data/Stores/orderstore";

const NewOrderModal = ({ premodal }) => {
	// const navigate = useNavigate();
	const { homepending, deleteOrders } = useOrdersStore(),
		[reason, setReason] = useState(""),
		[modal, setModal] = useState("new-order"),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (modal?.includes("decline"))
			if (!reason) return toast.info("Reason is required");
		setLoading(true);
		let newState = {
			order: homepending?.docs?.[0]?._id,
			creativeResponse: modal?.includes("decline") ? "declined" : "approved",
		};
		if (reason)
			newState = {
				...newState,
				creativeReason: reason,
			};
		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/order/creative-response`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			deleteOrders(response);
			setModal(modal?.includes("decline") ? "done" : "accept");
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		// if (premodal) setModal(premodal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [premodal]);

	console.log({ modal, premodal });

	return (
		<>
			{modal === "new-order" && (
				<NewOrderModal
					handleClose={() => setModal("")}
					handleDecline={() => setModal("decline")}
					handleAccept={handleSubmit}
					order={homepending?.docs?.[0]}
					loading={loading}
				/>
			)}
			{modal === "decline" && (
				<DeclineIntentModal
					handleClose={() => setModal("")}
					handleDone={handleSubmit}
					order={homepending?.docs?.[0]}
					loading={loading}
					setReason={setReason}
					reason={reason}
				/>
			)}
			{modal === "done" && (
				<DoneModalAccept
					type={"decline"}
					handleClose={() => setModal("")}
					order={homepending?.docs?.[0]}
				/>
			)}
			{modal === "accept" && (
				<DoneModalAccept
					handleClose={() => setModal("")}
					type={"accept"}
					order={homepending?.docs?.[0]}
				/>
			)}
		</>
	);
};

export default NewOrderModal;
