import React, { useState, useEffect, Fragment } from "react";
import Header from "../components/header";
import PrimaryBtn, { MainBtn } from "../components/button";
import Footer from "../components/footer";
import useOrdersStore from "../data/Stores/orderstore";
import moment from "moment";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import { LoadMore, MainPaginate } from "../components/Pagination";
import ModalContainer from "../components/modals/modalcontainer";
import { usePaystackPayment } from "react-paystack";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import { IconContext } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const MainOrders = () => {
	const [tab, setTab] = useState("pending"),
		[datum, setDatum] = useState(null),
		{
			pending,
			completed,
			approved,
			getCompletedOrders,
			getApprovedOrders,
			getPendingOrders,
			getOngoingOrders,
			ongoing,
		} = useOrdersStore(),
		tabList = [
			{ name: "pending", value: pending?.totalDocs },
			{ name: "accepted", value: approved?.totalDocs },
			{ name: "on-going", value: ongoing?.totalDocs },
			{ name: "completed", value: completed?.totalDocs },
		];

	useEffect(() => {
		if (tab === "completed") setDatum(completed);
		else if (tab === "accepted") setDatum(approved);
		else if (tab === "pending") setDatum(pending);
		else if (tab === "on-going") setDatum(ongoing);
	}, [tab, pending, completed, approved, ongoing]);

	useEffect(() => {
		apiCall("get", `/api/v1/order?status=pending`, null, getPendingOrders);
		apiCall("get", `/api/v1/order?status=approved`, null, getApprovedOrders);
		apiCall("get", `/api/v1/order?status=on-going`, null, getOngoingOrders);
		apiCall("get", `/api/v1/order?status=completed`, null, getCompletedOrders);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/order?status=${tab === "accepted" ? "approved" : tab}`,
			null,
			tab === "completed"
				? getCompletedOrders
				: tab === "accepted"
				? getApprovedOrders
				: tab === "on-going"
				? getOngoingOrders
				: getPendingOrders
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[search] = useState(""),
		[loading, setLoading] = useState(null),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/order?status=${tab === "accepted" ? "approved" : tab}&limit=${
				datum?.limit * datum?.nextPage
			}${search ? `&search=${search}` : ""}`,
			null,
			tab === "completed"
				? getCompletedOrders
				: tab === "accepted"
				? getApprovedOrders
				: tab === "on-going"
				? getOngoingOrders
				: getPendingOrders
		);
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-min-h-screen tw-w-full tw-bg-white tw-pt-8">
				<Header bg={"#002F19"} />
				<div className="tw-my-16 container">
					<h1 className="lg:tw-text-xm tw-font-bold tw-text-primary satoshi">
						Orders
					</h1>
					<div className="tw-mt-8 tw-grid lg:tw-grid-cols-4 tw-gap-5">
						{tabList?.map((it, i) => (
							<div onClick={() => setTab(it?.name)}>
								<h5
									className={`tw-text-xs satoshi tw-font-bold tw-text-primary tw-capitalize tw-cursor-pointer ${
										tab === it?.name ? " tw-border-b-primary tw-border-b-2" : ""
									}`}>
									{it?.name}{" "}
									<span className="tw-font-normal">({it?.value})</span>
								</h5>
							</div>
						))}
					</div>
					<ListOrder accepted={currentItems} btn={tab} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && datum?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

const Orders = () => <MainOrders />;

export default Orders;

const ListOrder = ({ accepted, btn }) => {
	let { user } = useAuthStore(),
		[modal, setModal] = useState("");

	return (
		<div className="tw-mt-8 tw-grid lg:tw-grid-cols-3 tw-gap-5">
			{accepted?.map((it, i) => (
				<div key={i} className="tw-mt-6 tw-space-y-3">
					<div className="tw-min-h-32 tw-w-full tw-border-[0.5px] tw-border-[#7B87944D] tw-rounded-xl tw-p-4">
						<strong className="tw-text-base tw-capitalize tw-font-medium satoshi tw-text-primary">
							{it?.title}
						</strong>
						<div>
							<p className="tw-text-xs tw-font-normal satoshi tw-text-primary">
								{it?.additionalInformation}
							</p>
						</div>
						<CheckList order={it} />
						{btn === "accepted" &&
							user?.privilege === "client" &&
							it?.payment &&
							it?.payment?.statusText === "pending" && (
								<Fragment>
									<div className="tw-flex tw-mt-4 tw-justify-end">
										<PrimaryBtn
											bg={"#E4FFEF"}
											color={"black"}
											text={"Make Payment"}
											onClick={() => {
												setModal(it);
											}}
										/>
									</div>
								</Fragment>
							)}
						{btn === "on-going" && it?.status === "on-going" && (
							<Fragment>
								<ValidateOrder order={it} />
							</Fragment>
						)}
					</div>
				</div>
			))}
			<MakeCardsPaystack
				payment={modal?.payment}
				handleClose={() => setModal(null)}
			/>
		</div>
	);
};

export const CheckList = ({ order }) => {
	const details = [
		{
			name: order?.time ? "Date Needed" : "Starting Date",
			value: `${moment(order?.date).format("Do MMMM, YYYY")}`,
		},
		{
			name: `No Of ${order?.durationType}s`,
			value: `${order?.duration} ${order?.durationType}`,
		},
		order?.time
			? {
					name: "Starting Time",
					value: `${moment(order?.time, "hh:mm").format("hh:mm A")}`,
			  }
			: null,
		order?.availability
			? {
					name: "Selected Days",
					value: order?.availability,
			  }
			: null,
	];
	return (
		<>
			<div className="tw-p-3 tw-mt-5 tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D]">
				<div className="tw-space-y-1">
					{details
						?.filter(it => it)
						?.map((it, i) => (
							<div
								key={i}
								className="tw-flex tw-justify-between tw-items-center">
								<div>
									<small className="tw-text-base tw-text-primary satoshi tw-font-normal">
										{it?.name}
									</small>
								</div>
								<div>
									{Array?.isArray(it?.value) ? (
										<>
											{it?.value?.map((ic, a) => (
												<strong
													key={a}
													className="tw-text-lg tw-font-medium satoshi tw-text-primary tw-block">
													{ic?.day} :{" "}
													{moment(ic?.time, "hh:mm").format("hh:mm A")}
												</strong>
											))}
										</>
									) : (
										<strong className="tw-text-lg tw-font-medium satoshi tw-text-primary">
											{it?.value}
										</strong>
									)}
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export const MakeCardsPaystack = ({ payment, handleClose }) => {
	let { user } = useAuthStore(),
		[reference, setReference] = useState(""),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		mainName = `${user?.firstName || ""} ${user?.lastName || ""}`,
		config = {
			email: user?.email,
			amount: Number(payment?.amount * 100),
			publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
			metadata: {
				name: mainName,
				phone: user?.telephone || "",
				custom_fields: [
					{
						display_name: "Full Name",
						variable_name: "full_name",
						value: mainName,
					},
					{
						display_name: "Phone Number",
						variable_name: "phone_number",
						value: user?.telephone,
					},
				],
			},
			reference: reference ? reference?.toString()?.split("|")?.join("") : "",
		},
		initializePayment = usePaystackPayment(config);
	console.log({ config, payment });
	useEffect(() => {
		if (payment) {
			setReference(payment?.itemId);
		}
	}, [payment]);

	let handleSuccess = async item => {
		if (!item) return toast.info("Reason is required");
		setLoading(true);

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/payment/manage-paystack`,
			{
				payment: payment?._id,
				payment_data: {
					reference: item?.reference,
				},
			}
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			console.log({ response }, "paystack");
			navigate("/");
			handleClose();
			return;
		}
		setLoading(false);
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log("closed");
		handleClose();
	};

	const onSuccess = ref => {
		console.log({ ref }, "success");
		handleSuccess(ref);
	};

	useEffect(() => {
		if (reference) {
			initializePayment({ onSuccess, onClose });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reference]);

	let [loading, setLoading] = useState(false);

	if (!payment) return <></>;

	return (
		<>
			<ModalContainer handleClose={() => {}}>
				<div
					style={{
						overflowY: "scroll",
					}}
					className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
					<div className="tw-pb-10">
						<div
							onClick={() => {
								handleClose();
							}}
							className="tw-float-right tw-size-5 tw-rounded-lg tw-bg-black tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
							<IconContext.Provider value={{ color: "white" }}>
								<IoMdClose size={15} />
							</IconContext.Provider>
						</div>
					</div>
					<div className="tw-flex tw-align-center tw-justify-center">
						<MainBtn text={"Paystack"} loading={loading} onClick={() => {}} />
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export const ValidateOrder = ({ order }) => {
	let { user } = useAuthStore(),
		[reason] = useState(""),
		{ returnErrors } = useErrorStore(),
		[modal, setModal] = useState("");

	console.log({ order });

	let handleSuccess = async item => {
		if (!item) return toast.info("Reason is required");
		setLoading(item);
		let newState = {
			response: item,
			order: order?._id,
		};

		if (reason)
			newState = {
				...newState,
				reason,
			};
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/order/creative-response`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setModal("");
			return;
		}
		setLoading(false);
	};

	let [loading, setLoading] = useState(false);

	if (!order) return <></>;

	return (
		<>
			<div className="tw-flex tw-mt-4 tw-justify-end">
				<PrimaryBtn
					bg={"#E4FFEF"}
					color={"black"}
					text={"Validate Completion"}
					onClick={() => {
						setModal("open");
					}}
				/>
			</div>
			{modal && (
				<ModalContainer handleClose={() => {}}>
					<div>
						<h2 className="tw-text-xl tw-font-[900] satoshi tw-text-primary">
							Validate Order {order?.title} Completion
						</h2>
						<div className="tw-p-3 tw-w-full tw-rounded-xl">
							<strong className="tw-text-base tw-font-medium satoshi tw-text-primary tw-text-center">
								Do you confirm that{" "}
								{user?.privilege === "creative" ? "you have " : "creative has "}{" "}
								completed this order?
							</strong>
						</div>
						<div className="tw-mt-8 tw-flex tw-justify-center">
							<div className="tw-flex tw-gap-5 tw-items-center">
								<PrimaryBtn
									onClick={() => handleSuccess("completed")}
									text={"Completed"}
									bg={"#59FE9C"}
									loading={loading === "completed"}
									color={"#002F19"}
								/>
								{user?.privilege === "client" && (
									<PrimaryBtn
										bg={"white"}
										onClick={() => handleSuccess("not-completed")}
										color={"#002F19"}
										loadCss={"#002F19"}
										text={"Not Completed"}
										loading={loading === "not-completed"}
									/>
								)}
								<PrimaryBtn
									bg={"#E4FFEF"}
									color={"black"}
									text={"Cancel"}
									onClick={() => {
										setModal("");
									}}
								/>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</>
	);
};
