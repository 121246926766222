import React, { useState, useEffect } from "react";
// import Textinput from "../../components/inputs";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import { motion } from "framer-motion";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import useAuthStore from "../../data/Stores/AuthStore";
import Talent from "../../assets/designers2.png";

const Fees = () => {
	return (
		<div>
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const init = {},
		[state, setState] = useState(init),
		// textChange = e => {
		// 	let { name, value } = e.target;
		// 	setState({ ...state, [name]: value });
		// },
		navigate = useNavigate(),
		items = useLocation()?.state,
		{ settings } = useAuthStore(),
		handleSubmit = e => {
			e?.preventDefault();
			navigate("/talent-register/terms", { state });
		};

	useEffect(() => {
		if (items) setState({ ...state, ...items });
		else navigate(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return (
		<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
			<div
				className="tw-w-full tw-h-full signup-talent"
				style={{
					backgroundImage: `url(${settings?.registerImage?.url || Talent})`,
				}}></div>
			<motion.div
				className="m-auto w-100 h-100"
				style={{
					maxWidth: "550px",
				}}
				initial={{
					x: 250,
				}}
				animate={{
					x: 0,
				}}
				transition={{
					duration: 1,
					delay: 1,
					type: "tween",
				}}>
				{" "}
				<div className="lg:tw-w-full tw-w-11/12 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
					<div>
						<strong className="tw-text-4xl bolder satoshi tw-text-primary">
							<span
								className="tw-me-3 tw-inline-flex tw-cursor-pointer"
								onClick={() => {
									navigate("/talent-register/interests", { state });
								}}>
								<IoArrowUndoCircleOutline size={24} />
							</span>{" "}
							Fees and Charges
						</strong>
						<form onSubmit={handleSubmit} action="" className="tw-mt-10">
							<div className="tw-space-y-4">
								{/* <Textinput
									label={"Price for Service"}
									value={state?.serviceFee}
									onChange={textChange}
									name="serviceFee"
								/> */}
								<div>
									<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
										Price for Service
									</small>
								</div>
								<NumericFormat
									prefix={"(NGN) "}
									suffix={""}
									className="lg:tw-w-96 tw-w-full tw-pl-6 tw-h-12 tw-rounded-md tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
									value={state?.serviceFee}
									placeholder={"(NGN) 2,000"}
									displayType="input"
									thousandSeparator={true}
									onValueChange={val =>
										setState({ ...state, serviceFee: val?.floatValue })
									}
									min={0}
									inputMode={"decimal"}
									renderText={(value, props) => <span {...props}>{value}</span>}
									allowNegative={false}
								/>
							</div>
							<div className="tw-mt-10">
								<button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
									Next
								</button>
								<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
									Already have an account?{" "}
									<span
										onClick={() => navigate("/login")}
										className="tw-font-bold tw-underline tw-cursor-pointer">
										Sign In
									</span>
								</h6>
							</div>
						</form>
					</div>
				</div>
			</motion.div>
		</section>
	);
};

export default Fees;
