import React, { useState, useContext } from "react";
import Header from "../components/header";
import ProfileImg from "../assets/profile.svg";
import Avatar from "../assets/review.svg";
import EditProfilePriceModal, {
	EditBankDetails,
	EditCategory,
	EditCertificates,
	EditDescription,
	EditInterestsModal,
	EditProfileName,
	EditProfilePicture,
} from "../components/modals/edit-modal";
import { UpdateDone } from "../components/modals/donemodal";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import { GlobalState } from "../data/Context";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import File from "../assets/file.svg";
import axios from "axios";
import fileDownload from "js-file-download";

import DOMPurify from "dompurify";
import { FaPen } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { TagsComp } from "../pages/artists/[id]";
export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const EditProfile = () => {
	return (
		<div>
			<section className="tw-bg-white tw-min-h-screen tw-w-full tw-pt-12">
				<Header bg={"#002F19"} />
				<MainProfileSection />
			</section>
		</div>
	);
};

export const MainProfileSection = () => {
	const [modal, setModal] = useState("");

	const { user, setUser, setBank, bank } = useAuthStore(),
		{ nairaSign, numberWithCommas } = useContext(GlobalState),
		init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		navigate = useNavigate(),
		{ returnErrors } = useErrorStore();

	const Arr = [
		{
			name: "Account Name",
			value: bank?.accountName || "",
		},
		{
			name: "Account Number",
			value: bank?.accountNumber || "",
		},
		{
			name: "Bank Name",
			value: bank?.bankName || "",
		},
	];

	let handleSubmit = async (e, d) => {
		e?.preventDefault();

		setLoading(true);
		console.log({ state });
		let newState = { ...state };
		if (d) newState = { ...newState, ...d };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		if (state?.certificate)
			newState = {
				...newState,
				intendedFile: state?.certificate,
				intendedFileName: "certificate",
			};
		// newState = { ...newState, privilege: "client" };

		let { response, errArr, errMsg } = await apiCall(
			modal === "edit-bank" ? "post" : "put",
			modal === "edit-bank" ? `/api/v1/user/manage-bank` : `/api/v1/user`,
			newState,
			null,
			["add-image", "add-cert"]?.includes(modal) &&
				(state?.image || state?.certificate)
				? { "Content-Type": "multipart/form-data" }
				: null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			if (modal === "edit-bank") setBank(response);
			else setUser(response);
			setModal("done");
			return;
		}
		setLoading(false);
	};

	const DownloadCV = async () => {
		if (!user?.certificate) return;
		let res = await axios.get(user?.certificate?.url, {
			responseType: "blob",
		});
		console.log({ res });
		fileDownload(res.data, `${user?.certificate?.name}.${user?.format}`);
	};

	return (
		<section className="tw-w-full tw-mb-16">
			<div
				style={{
					background: "linear-gradient(91.73deg, #006823 -2.47%, #00270D 100%)",
				}}
				className="tw-w-full tw-h-56"></div>
			<div className="container">
				<div className="lg:tw-w-1/2">
					<div className="tw-mb-3">
						<img
							src={user?.image?.url || ProfileImg}
							alt=""
							className={`-tw-mt-12 tw-rounded-full tw-object-cover ${
								user?.image?.url ? " tw-h-32 tw-w-32" : ""
							}`}
						/>
						<FaPen
							onClick={() => setModal("add-image")}
							className={`tw-cursor-pointer ${
								user?.image?.url ? "-tw-mt-6 tw-ml-28 " : "-tw-mt-8 tw-ml-24 "
							}`}
							size={20}
						/>
					</div>
					<div className="tw-flex tw-items-center tw-gap-4">
						<strong className="tw-text-4xl tw-font-bold satoshi tw-text-[#252525]">
							{user?.firstName} {user?.lastName}
						</strong>
						<div onClick={() => setModal("edit-name")}>
							<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
								Edit
							</strong>
						</div>
					</div>
					<div>
						<small className="tw-text-2xl tw-font-normal satoshi tw-text-[#252525]">
							{user?.email}
						</small>
					</div>
					{user?.privilege === "creative" && (
						<div className="tw-mt-14 tw-space-y-4">
							<DetailsComp>
								<div>
									<h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
										Price Per Show
									</h4>
									<div>
										<strong className="tw-text-base tw-font-bold tw-text-secondary satoshi">
											{nairaSign}{" "}
											{numberWithCommas(
												Number(user?.serviceFee || 0).toFixed(2)
											)}
										</strong>
									</div>
								</div>
								<div onClick={() => setModal("edit-price")}>
									<strong className="tw-font-medium tw-text-base tw-cursor-pointer tw-text-primary satoshi">
										Edit Price
									</strong>
								</div>
							</DetailsComp>

							<DetailsComp>
								<div>
									<h4 className="tw-text-base tw-font-normal tw-text-secondary satoshi">
										Description
									</h4>
									<div>
										<p
											className="tw-text-sm tw-max-w-sm tw-font-normal tw-text-primary satoshi"
											dangerouslySetInnerHTML={createMarkup(user?.bio || "")}
										/>
									</div>
								</div>
								<div onClick={() => setModal("edit-description")}>
									<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
										Edit
									</strong>
								</div>
							</DetailsComp>
							<DetailsComp>
								<div>
									<h4 className="tw-text-base tw-font-normal tw-text-secondary satoshi">
										Category
									</h4>
									<div>
										<strong className="tw-text-base tw-font-bold tw-text-secondary satoshi">
											{user?.category?.[0]?.title}
										</strong>
									</div>
								</div>
								{/* <div onClick={() => setModal("edit-category")}>
								<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
									Change
								</strong>
							</div> */}
							</DetailsComp>
							{/* <DetailsComp>
              <div>
                <h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
                  Skill and expertise
                </h4>
                <div>
                  <strong className="tw-text-base tw-font-bold tw-text-secondary satoshi">
                    Music
                  </strong>
                </div>
              </div>
              <div>
                <strong className="tw-font-medium tw-text-base tw-text-primary satoshi">
                  Add New
                </strong>
              </div>
            </DetailsComp> */}
							<DetailsComp>
								<div>
									<h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
										Preferences and Interest
									</h4>
									<div className="tw-flex tw-gap-3">
										{user?.interests?.map((it, i) => (
											<TagsComp text={it?.title} key={i} />
										))}
									</div>
								</div>
								<div onClick={() => setModal("edit-interests")}>
									<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
										Add New
									</strong>
								</div>
							</DetailsComp>
							<DetailsComp>
								<div>
									<h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
										Uploaded Certificates
									</h4>
									<div className="tw-w-full tw-rounded-xl tw-border-[0.5px] tw-border-[#7B87944D] tw-h-20 tw-flex tw-px-6 tw-items-center">
										<div
											onClick={DownloadCV}
											className="tw-flex tw-items-center tw-gap-5 tw-cursor-pointer">
											<img src={File} alt="" className="" />
											<div>
												<strong className="tw-text-base tw-border-b tw-pb-1 tw-border-primary tw-font-medium tw-text-primary satoshi">
													{user?.certificate?.name || "Certificates"}
												</strong>
											</div>
										</div>
									</div>
								</div>
								<div onClick={() => setModal("add-cert")}>
									<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
										Add New
									</strong>
								</div>
							</DetailsComp>
							<DetailsComp>
								<div>
									<h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
										Bank Details
									</h4>
									<div className="tw-space-y-4 tw-mt-4">
										{Arr?.map(it => (
											<div key={it?.name} className="">
												<strong className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
													{it?.name}
												</strong>
												<div>
													<small className="tw-text-secondary tw-text-base tw-font-bold satoshi">
														{it?.value}
													</small>
												</div>
											</div>
										))}
									</div>
								</div>
								<div onClick={() => setModal("edit-bank")}>
									<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
										Change Detail
									</strong>
								</div>
							</DetailsComp>
							<DetailsComp>
								<div>
									<h4 className="tw-text-xl tw-font-normal tw-text-secondary satoshi">
										Availability
									</h4>
								</div>
								<div onClick={() => navigate("/availability")}>
									<strong className="tw-font-medium tw-cursor-pointer tw-text-base tw-text-primary satoshi">
										Update Detail
									</strong>
								</div>
							</DetailsComp>
							<div className="tw-mt-16">
								<div className="tw-border-b tw-border-b-[#01346766] tw-pb-4">
									<div>
										{" "}
										<h3 className="tw-text-xl tw-font-bold satoshi tw-text-[#000851]">
											Reviews
										</h3>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-[#000851]">
											34 Ratings
										</small>
									</div>
								</div>
								<div className="tw-mt-8">
									<h3 className="tw-text-xl tw-font-bold satoshi tw-text-[#000851]">
										Reviews
									</h3>
									<div className="tw-space-y-5">
										<div>
											<div className="tw-flex tw-gap-3 tw-items-center">
												<img src={Avatar} alt="" className="" />
												<div>
													<strong className="tw-font-bold satoshi tw-text-sm tw-text-[#000851]">
														Theresa Webb
													</strong>
													<div>
														{" "}
														<small className="tw-text-sm tw-font-normal tw-text-[#000851] satoshi">
															4 May, 2025
														</small>
													</div>
												</div>
											</div>
											<div className="tw-w-full tw-px-4 tw-py-2 tw-mt-6 tw-bg-[#F4FAFF] tw-rounded-xl">
												<p className="tw-text-darkblue tw-font-normal satoshi tw-text-sm">
													Amet minim mollit non deserunt ullamco est sit aliqua
													dolor do amet sint. Velit officia consequat duis enim
													velit mollit. Exercitation veniam consequat sunt
													nostrud amet.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{modal === "edit-price" && (
				<EditProfilePriceModal
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "edit-description" && (
				<EditDescription
					user={user}
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
				/>
			)}
			{modal === "edit-category" && (
				<EditCategory
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "edit-interests" && (
				<EditInterestsModal
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "edit-bank" && (
				<EditBankDetails
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "edit-name" && (
				<EditProfileName
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "add-cert" && (
				<EditCertificates
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "add-image" && (
				<EditProfilePicture
					handleClose={() => setModal("")}
					handleModal={handleSubmit}
					loading={loading}
					textChange={textChange}
					setState={setState}
					state={state}
					user={user}
				/>
			)}
			{modal === "done" && <UpdateDone handleClose={() => setModal("")} />}
		</section>
	);
};

const DetailsComp = ({ children }) => {
	return (
		<div className="tw-w-full tw-flex tw-mt-6 tw-justify-between tw-border-[#EEEEEE] tw-rounded-xl tw-border tw-p-4 tw-items-center">
			{children}
		</div>
	);
};

export default EditProfile;
