import React, { useState, useEffect, Fragment } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa6";
import Avatar from "../assets/chat-avatar.svg";
import { ChatBtn } from "../components/button";
import { FaAngleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import useSocketStore from "../data/Stores/socketstore";
import moment from "moment";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { BiLoader, BiCheck, BiCheckDouble } from "react-icons/bi";

const Chat = () => {
	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-pt-8">
				<Header bg={"#002F19"} />
				<MainChatSection />
				<Footer />
			</div>
		</div>
	);
};

export const MainChatSection = () => {
	const { state } = useLocation(),
		navigate = useNavigate(),
		{ data, message, resetSocket, getChats } = useSocketStore(),
		[chats, setChats] = useState(null),
		[content, setContent] = useState(""),
		{ returnErrors } = useErrorStore(),
		{ user } = useAuthStore(),
		[topper, setTopper] = useState(null);

	useEffect(() => {
		if (state) {
			setChats(null);
			resetSocket();
			apiCall("get", `/api/v1/chat?chat=${state?.chat}`, null, setChats);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		apiCall("get", "/api/v1/chat/manage-chat", null, getChats);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state && data?.docs?.length > 0) {
			data?.docs?.map(it => it?._id === state?.chat && setTopper(it));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, data]);

	useEffect(() => {
		if (message) {
			if (message?.chat === state?.chat)
				setChats({
					...chats,
					data: {
						...chats?.data,
						docs: Array.isArray(chats?.data?.docs)
							? [...chats?.data?.docs, message]
							: [message],
					},
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, message]);

	// console.log({ chats });

	let handleSubmit = async e => {
		e?.preventDefault();
		let newState = {
			chat: state?.chat || state?._id,
			content,
			type: "text",
			initId: v4(),
			createdAt: new Date(),
		};
		console.log({ newState });
		if (!state?.chat || !content) return;

		setChats({
			...chats,
			data: {
				...chats?.data,
				docs: Array.isArray(chats?.data?.docs)
					? [...chats?.data?.docs, { ...newState, sender: user }]
					: [{ ...newState, sender: user }],
			},
		});
		setContent("");
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/chat`,
			newState,
			null,
			null,
			"noToast"
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			return returnErrors(errArr);
		}
		if (errMsg) {
			return toast.error(errMsg);
		}
		if (response) {
			let newD = chats?.data?.docs;
			console.log({ response });
			console.log({ newD }, "one");
			let findIt = newD?.find(it => it?.initId === newState?.initId);
			if (findIt)
				newD = [
					...newD?.map(it =>
						it?.initId === newState?.initId ? response?.data : it
					),
				];
			else {
				newD = [...newD, response?.data];
			}

			console.log({ newD }, "two");
			setChats({
				...chats,
				data: {
					...chats?.data,
					docs: newD,
				},
			});
			return;
		}
	};

	return (
		<section className="tw-py-16 container tw-bg-white">
			<div>
				<h1 className="tw-text-primary bold tw-text-xm">Chats</h1>
				<div className="tw-w-full tw-h-screen tw-border tw-border-[#CFCFCF] tw-rounded-xl tw-flex">
					<div
						className={`lg:tw-w-[30%] tw-w-full tw-border-r tw-h-full tw-border-r-[#CFCFCF] tw-p-8 ${
							state ? "lg:tw-block tw-hidden" : "tw-block"
						}`}>
						<div className="tw-relative tw-mt-6 tw-w-full tw-h-10">
							<div className="tw-absolute tw-top-3 tw-left-2">
								<CiSearch />
							</div>
							<input
								type="text"
								className="tw-h-full tw-w-full tw-border tw-border-[#EEEEEE] tw-text-sm tw-font-medium tw-text-primary tw-rounded tw-pl-7"
								placeholder="Search"
							/>
						</div>
						<div className="tw-mt-8 tw-flex tw-gap-3 tw-items-center">
							<h4 className="tw-text-base tw-font-medium satoshi tw-text-darkblue">
								All Recent
							</h4>
							<FaAngleDown />
						</div>
						<div className="tw-mt-5 tw-space-y-4 tw-w-full">
							{data.docs?.map((it, i) => (
								<div
									onClick={() =>
										navigate("/chat", { state: { ...it, chat: it?._id } })
									}
									key={i}
									className="tw-flex tw-cursor-pointer tw-gap-3 tw-w-full">
									<img src={Avatar} alt="" className="" />
									<div className="tw-w-full">
										<div className="tw-flex tw-justify-between tw-w-full tw-items-center">
											<div>
												<strong className="tw-text-xs tw-font-medium satoshi tw-text-secondary tw-line-clamp-1">
													{it?.title}
												</strong>
											</div>
											<div>
												<small className="tw-text-xs tw-font-normal satoshi tw-text-secondary">
													{it?.createdAt
														? moment(it?.createdAt).format("DD/MM/YYYY")
														: null}
												</small>
											</div>
										</div>
										<div>
											<strong className="tw-text-xs tw-font-medium satoshi tw-text-secondary tw-line-clamp-2">
												{it?.lastMessage?.content}
											</strong>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div
						className={`lg:tw-w-[70%] tw-relative tw-h-full ${
							state ? "tw-block" : "lg:tw-block tw-hidden"
						}`}>
						{state ? (
							<>
								<div className="">
									<div className=" tw-border-b p-2">
										<div className="tw-block lg:tw-hidden">
											<FaAngleLeft
												size={20}
												onClick={() => navigate(-1)}
												className="tw-cursor-pointer"
											/>
										</div>
										<div className="tw-flex tw-cursor-pointer tw-gap-3 tw-w-full">
											<img src={Avatar} alt="" className="" />
											<div className="tw-w-full">
												<div className="tw-flex tw-justify-between tw-w-full tw-items-center">
													<div>
														<strong className="tw-text-xs tw-font-medium satoshi tw-text-secondary tw-line-clamp-1">
															{topper?.title}
														</strong>
													</div>
													<div>
														<small className="tw-text-xs tw-font-normal satoshi tw-text-secondary">
															{topper?.createdAt
																? moment(topper?.createdAt).format("DD/MM/YYYY")
																: null}
														</small>
													</div>
												</div>
												<div>
													<strong className="tw-text-xs tw-font-medium satoshi tw-text-secondary tw-line-clamp-1 tw-uppercase">
														{topper?.recipients?.map((it, i) => (
															<span key={i}>
																{it?.privilege}
																{i !== topper?.recipients?.length - 1 && (
																	<>, </>
																)}
															</span>
														))}
													</strong>
												</div>
											</div>
										</div>
									</div>
									<div className="tw-space-y-4 tw-p-8 tw-overflow-y-auto tw-max-h-[630px]">
										{chats?.data?.docs?.map((ct, m) => (
											<Fragment key={m}>
												<div
													className={`tw-flex ${
														ct?.sender?._id === user?._id
															? "tw-justify-end"
															: "tw-justify-start"
													}`}>
													<div>
														<p
															className={`tw-text-xs my-0 tw-uppercase ${
																ct?.sender?._id === user?._id ? "" : "tw-ml-8"
															}`}>
															{ct?.sender?.privilege}
														</p>
														<div
															className={` tw-w-96 tw-p-5  ${
																ct?.sender?._id === user?._id
																	? "tw-bg-[#F4FAFF] tw-mr-8"
																	: "tw-bg-white tw-border tw-border-[#EEEEEE] tw-ml-8"
															} `}>
															<small className="tw-text-sm satoshi tw-font-normal tw-text-[#000851]">
																{ct?.content}
															</small>
														</div>
														<div className="tw-flex tw-justify-between tw-items-center">
															<div
																className={`tw-flex tw-items-center ${
																	ct?.sender?._id === user?._id
																		? "tw-order-1"
																		: " tw-order-2"
																}`}>
																<small className="tw-text-sm tw-font-normal satoshi tw-text-[#000851]">
																	{moment(ct?.createdAt).diff(
																		moment(),
																		"years"
																	) < 0
																		? moment(ct?.createdAt).format("L hh:mm A")
																		: moment(ct?.createdAt).diff(
																				moment(),
																				"months"
																		  ) < 0
																		? moment(ct?.createdAt).format(
																				"DD/MM hh:mm A"
																		  )
																		: moment(ct?.createdAt).diff(
																				moment(),
																				"days"
																		  ) < 0
																		? moment(ct?.createdAt).format(
																				"DD/MM hh:mm A"
																		  )
																		: moment(ct?.createdAt).format("hh:mm A")}
																</small>
																<span className="tw-ml-2">
																	{ct?.sender?._id === user?._id &&
																		(ct?.isDeliveredTo?.includes(user?._id) ? (
																			<BiCheckDouble
																				className={`fontReduce ${
																					ct?.isSeenTo?.includes(user?._id)
																						? " tw-text-[#002F19]"
																						: "text-dark"
																				}`}
																			/>
																		) : ct?.isSent ? (
																			<BiCheck className="" />
																		) : (
																			<BiLoader className="" />
																		))}
																</span>
															</div>
															<img
																src={ct?.sender?.image?.url || Avatar}
																alt=""
																className={`tw-size-8 tw-rounded-full ${
																	ct?.sender?._id === user?._id
																		? "tw-order-2"
																		: " tw-order-1"
																}`}
															/>
														</div>
													</div>
												</div>
											</Fragment>
										))}
									</div>
								</div>
								<div className="tw-absolute tw-w-full tw-bottom-6 tw-px-5">
									<div className="tw-w-full tw-bg-[#F3F5F5] tw-rounded-lg tw-relative tw-h-12 tw-border-[0.4px] tw-border-[#FFFFFF33]">
										{topper?.status === "open" ? (
											<>
												<input
													type="text"
													className="tw-rounded-lg tw-w-[90%] tw-h-full tw-bg-transparent tw-border-[0.4px] tw-border-[#FFFFFF33]"
													value={content}
													onChange={e => setContent(e?.target?.value)}
												/>
												<div className="tw-absolute tw-right-2 tw-top-1">
													<ChatBtn
														bg={"#000851"}
														color={"white"}
														text={"Send"}
														onClick={handleSubmit}
													/>
												</div>
											</>
										) : (
											<>
												<strong className="tw-text-lg tw-font-medium satoshi tw-text-secondary tw-flex tw-items-center tw-justify-center tw-text-center">
													Chat is currently not opened
												</strong>
											</>
										)}
									</div>
								</div>
							</>
						) : (
							<>
								<div className={`tw-bg-[#dffff0] tw-p-8 tw-h-full aboutScreen`}>
									<div
										className="d-flex justify-content-center 
                align-items-center flex-column h-100 m-auto">
										<img src={logo} alt="logo" className="img-fluid my-3" />
										<small className="d-block satoshi">
											Interact with others seamlessly
										</small>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Chat;
