import React from "react";
import { HeroSection, Portfolio } from "./home";
import { MdSearch } from "react-icons/md";
import Img1 from "../assets/feat.png";
import Img2 from "../assets/dancers.png";
import Img3 from "../assets/writers.png";
import img4 from "../assets/videographer.png";
import Img5 from "../assets/designers.png";
import Img6 from "../assets/designers2.png";
import Img7 from "../assets/videographer2.png";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import { useState } from "react";
import useCategoryStore from "../data/Stores/category";
import { useEffect } from "react";
import PrimaryBtn from "../components/button";
import { LoadMore } from "../components/Pagination";
import YouTubeModalPlayer from "../components/youtubemodalplayer";
import Time1 from "../assets/time1.png";
import Time2 from "../assets/time2.png";
import Time3 from "../assets/time3.png";
import Music from "../assets/musicimg.png";
import ReactPlayer from "react-player";
import FourBox from "../assets/fourbox.png";
import Wave from "../assets/wave.svg";

let imgArr = [
  {
    img: Img1,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: Img2,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: Img3,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: img4,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: Img5,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: Img6,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
  {
    img: Img7,
    class: "tw-w-full lg:tw-h-[440px] tw-cursor-pointer tw-relative",
  },
];

const Home = () => {
  const { isAuth, userPage, user } = useAuthStore();
  const navigate = useNavigate();
  return (
    <div>
      <HeroSection
        text={"Find Africa’s best music talent with ease."}
        desc={
          "Soundmap is the all-in-one platform for finding the best African talents for your music project. From musicians and songwriters to backup vocalists and instrumentalists, we bring the best talent to you."
        }
        btn_text={
          isAuth && userPage === "talent"
            ? "View Orders"
            : isAuth
            ? "Hire now"
            : "Get Started"
        }
        onClick={() =>
          isAuth && userPage === "talent"
            ? navigate("/orders")
            : isAuth
            ? navigate("/categories")
            : navigate("/register")
        }
      />
      <HiredSection />
      {!isAuth || user?.privilege === "client" ? <MainSectionCategory /> : null}
      <Portfolio />
      {/* <AboutUs /> */}
      {/* <Features /> */}
      <TimeSection />
      <MusicSection />
      <Footer />
    </div>
  );
};

export const HiredSection = () => {
  const navigate = useNavigate();
  const { isAuth, userPage } = useAuthStore();
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const toggleVideoModal = () => {
    setIsVideoOpen(!isVideoOpen);
  };
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container tw-py-16 tw-bg-[#F9F9F9] tw-flex tw-flex-col lg:tw-flex-row tw-gap-12">
        <div className="lg:tw-w-[45%] lg:tw-ml-12 tw-relative">
          <ReactPlayer
            data-aos="zoom-up"
            data-aos-duration="1500"
            url={"https://youtu.be/J2Tpldrady0"}
            width="100%"
            height="100%"
            style={{
              borderRadius: "24px",
            }}
            // style={{ position: "absolute", top: 0, left: 0 }}
            controls
          />
        </div>
        <div className="lg:tw-w-[55%]">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            className="lg:tw-text-xm tw-text-lg bolder tw-text-primary"
          >
            Let’s put you on the map.
          </h2>
          <div className="lg:tw-mt-8">
            <p
              data-aos="fade-up"
              data-aos-duration="1500"
              className="lg:tw-text-xl tw-text-sm tw-font-normal tw-text-primary satoshi"
            >
              Join a growing community of African music creatives. Soundmap
              connects Africa’s best talent with the people who need their
              services. Looking for your next gig? We’ve got you!
            </p>
            <p className="lg:tw-text-xl tw-text-sm tw-font-normal tw-text-primary satoshi">
              Soundmap is a link across the music industry, connecting talent
              with those who need their services. We provide you with vetted and
              trusted talents you might need in diverse areas of music
              production.
            </p>
          </div>
          <div className="lg:tw-mt-10 tw-flex tw-justify-between tw-items-center">
            <PrimaryBtn
              color={"black"}
              bg={"#59FE9C"}
              text={"Sign up"}
              onClick={() =>
                isAuth && userPage === "talent"
                  ? navigate("/orders")
                  : isAuth
                  ? navigate("/")
                  : navigate("/register")
              }
            />
            {/* <button
              onClick={toggleVideoModal}
              className="tw-flex tw-items-center tw-space-x-2 tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg tw-hover:bg-red-700 tw-transition-colors"
            >
              <PlayCircle size={24} />
              <span>Play Video</span>
            </button> */}
          </div>
        </div>
      </div>
      <YouTubeModalPlayer
        videoUrl={"https://youtu.be/J2Tpldrady0"}
        isOpen={isVideoOpen}
        toggleOpen={toggleVideoModal}
      />
    </section>
  );
};

export const MainSectionCategory = () => {
  const navigate = useNavigate();

  let [datum, setDatum] = useState(null),
    [loading, setLoading] = useState(""),
    [search, setSearch] = useState(""),
    {
      isFound,
      mainSearch,
      data,
      getCategory,
      getSearch,
      resetCategorySearch,
      getSearchCategory,
    } = useCategoryStore();

  useEffect(() => {
    if (isFound) setDatum(mainSearch);
    else setDatum(data);
  }, [data, isFound, mainSearch]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        resetCategorySearch();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await handleLoadMore();
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  let handleLoadMore = async (str) => {
    setLoading(str || "");
    if (search) {
      getSearch(search);
    }

    await apiCall(
      "get",
      `/api/v1/category?a=a${search ? `&search=${search}` : ""}${
        str ? `&limit=${datum?.limit * datum?.nextPage}` : ""
      }`,
      null,
      search ? getSearchCategory : getCategory
    );
    setLoading(false);
  };

  useEffect(() => {
    apiCall("get", "/api/v1/category", null, getCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!datum) return;

  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
          <div>
            {" "}
            <h2
              data-aos="fade-up"
              data-aos-duration="1500"
              className="tw-text-xm tw-text-primary tw-font-[900] satoshi"
            >
              Vetted, talented creatives for every project
            </h2>
            <p
              data-aos="fade-up"
              data-aos-duration="1500"
              className="tw-text-base tw-font-normal tw-text-primary satoshi"
            >
              We curate the best talent across different categories to match
              your needs for any project.
            </p>
          </div>
          <div>
            <div className="tw-h-12 tw-w-96 tw-relative">
              <input
                className="tw-h-full tw-w-full tw-rounded-3xl tw-border-[0.7px] tw-border-[#7B87944D] tw-pl-8"
                placeholder={"Search..."}
                required
                type="search"
                name="search"
                id="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <MdSearch className="tw-absolute tw-top-4 tw-left-3" />
            </div>
          </div>
        </div>
        <div className="tw-mt-16">
          <div className="tw-grid lg:tw-grid-cols-3 tw-mt-24 tw-gap-12">
            {datum?.docs?.map((it, i) => (
              <div
                key={i}
                data-aos="zoom-up"
                data-aos-duration="1500"
                onClick={() => navigate("/artists", { state: it })}
                className={`${imgArr?.[i % imgArr?.length]?.class}`}
              >
                <img
                  src={it?.image?.url || imgArr?.[i % imgArr?.length]?.img}
                  alt=""
                  className="tw-w-full tw-h-full tw-rounded-xl"
                />
                <FooterComp title={it?.title} subtitle={it?.description} />
              </div>
            ))}
          </div>
        </div>
        <div className="tw-mt-4 tw-flex tw-justify-center">
          <LoadMore
            next={datum?.hasNextPage}
            loading={loading === "loadmore"}
            handleLoadMore={() => handleLoadMore("loadmore")}
          />
        </div>
      </div>
    </section>
  );
};

const FooterComp = ({ title, subtitle }) => {
  return (
    <div>
      <div className="tw-absolute tw-w-full tw-z-50 tw-bottom-0 tw-px-5 tw-py-3 tw-rounded-b-xl tw-bg-black tw-bg-opacity-60">
        <h4 className="tw-text-2xl tw-font-bold satoshi tw-text-white">
          {title || "Artist"}
        </h4>
        <p className="tw-font-normal tw-text-sm satoshi tw-text-white tw-line-clamp-2">
          {subtitle ||
            `"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolor`}
        </p>
      </div>
    </div>
  );
};

const TimeSection = () => {
  return (
    <section className="tw-w-full tw-py-16">
      <div className="container">
        <h3
          data-aos="fade-up"
          data-aos-duration="1500"
          className="lg:tw-text-3xl tw-text-center tw-text-xl tw-font-extrabold satoshi tw-text-[#002F19]"
        >
          It’s time to get your music project off the ground
        </h3>
        <p
          data-aos="fade-up"
          data-aos-duration="1500"
          className="tw-text-primary tw-mt-3 tw-font-normal lg:tw-text-xl tw-text-base tw-max-w-3xl tw-mx-auto tw-text-center satoshi"
        >
          Soundmap is a link across the music industry, connecting talent with
          those who need their services. We provide you with vetted and trusted
          talents you might need in diverse areas of music production.
        </p>
        <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-justify-between tw-items-center">
          <img
            data-aos="fade-up"
            data-aos-duration="1500"
            src={Time1}
            alt=""
            className=""
          />
          <img
            data-aos="fade-up"
            data-aos-duration="1500"
            src={Time2}
            alt=""
            className="lg:tw-ml-6"
          />
          <img
            data-aos="fade-up"
            data-aos-duration="1500"
            src={Time3}
            alt=""
            className=""
          />
        </div>
      </div>
    </section>
  );
};

const MusicSection = () => {
  return (
    <section
      // style={{
      //   backgroundImage: `url(${Music})`,
      //   backgroundPosition: "left",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "contain",
      // }}
      className="tw-w-full tw-py-16 lg:tw-pr-10 lg:tw-px-0 tw-px-14 tw-relative tw-flex tw-justify-between tw-items-center"
    >
      <img
        data-aos="fade-up"
        data-aos-duration="1500"
        src={Music}
        alt=""
        className="tw-hidden lg:tw-block"
      />
      <img
        data-aos="fade-up"
        data-aos-duration="1500"
        src={FourBox}
        alt=""
        className="tw-hidden lg:tw-block"
      />
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="lg:tw-float-right"
      >
        <h3 className="lg:tw-text-3xl tw-text-right tw-text-xl tw-font-extrabold satoshi tw-text-[#002F19]">
          Keep the music going.
        </h3>
        <p className="tw-text-primary tw-mt-6 tw-font-normal lg:tw-text-xl tw-text-sm satoshi">
          Find world-class talent for your next music project.
        </p>
        <div>
          <ul className="tw-list-disc lg:tw-float-end tw-text-primary tw-font-normal lg:tw-text-xl tw-text-sm satoshi">
            <li>Vocalists</li>
            <li>Songwriters</li>
          </ul>
        </div>
        <img src={Wave} alt="" className="tw-mt-6 tw-h-16" />
      </div>
    </section>
  );
};

export default Home;