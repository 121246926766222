import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Talent from "../../assets/designers2.png";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../data/Stores/AuthStore";
import { motion } from "framer-motion";
import { NonAuthLogo } from "../client-register";

const SignUp = () => {
  return (
    <div>
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const [t, setT] = useState(""),
		{ setUserPage, settings } = useAuthStore(),
		navigate = useNavigate(),
		handleClick = name => {
			setT(name);
		};

	useEffect(() => {
		if (t === "talent") {
			navigate("/talent-register");
			setUserPage("talent");
		} else if (t === "client") {
			setUserPage("client");
			navigate("/client-register");
		}

		setT("");
	}, [t, navigate, setUserPage]);
	const types = [
		{
			name: "client",
			text: "I want to hire a talent",
		},
		{
			name: "talent",
			text: "I want to render a service",
		},
	];
	return (
		<section className="tw-min-h-screen tw-w-full tw-grid lg:tw-grid-cols-2">
			<div className="tw-w-full tw-hidden lg:tw-block tw-h-full">
				<img
					src={settings?.registerImage?.url || Talent}
					alt=""
					className="tw-h-full tw-w-full"
				/>
			</div>
			<motion.div
				className="m-auto w-100 h-100"
				style={{
					maxWidth: "550px",
				}}
				initial={{
					x: 250,
				}}
				animate={{
					x: 0,
				}}
				transition={{
					duration: 1,
					delay: 1,
					type: "tween",
				}}>
				<div className="lg:tw-w-full container tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center lg:tw-items-start">
					<div>
						<NonAuthLogo />
						<h1 className="tw-text-4xl tw-font-[900] satoshi tw-text-primary">
							Create your account
						</h1>
						<p className="tw-text-sm tw-font-normal satoshi tw-text-primary">
							Grwo with us
						</p>
						<div className="tw-w-full lg:tw-flex lg:tw-justify-center tw-grid tw-grid-cols-2 tw-gap-8">
							{types?.map((it, i) => (
								<OptionComp
									onClick={() => handleClick(it?.name)}
									key={i}
									text={it?.text}
								/>
							))}
						</div>
					</div>
				</div>
			</motion.div>
		</section>
	);
};

const OptionComp = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="tw-bg-[#F3F5F5] tw-cursor-pointer tw-h-44 lg:tw-w-60 tw-rounded-md tw-border-[0.4px] tw-border-white tw-flex tw-justify-center tw-items-center tw-px-4"
    >
      <h6 className="tw-text-2xl tw-font-medium satoshi tw-text-primary tw-text-center">
        {text}
      </h6>
    </div>
  );
};

export default SignUp;
