import React from "react";
import { Route, Routes } from "react-router-dom";
import PageRender from "./PageRender";
// import Home from "./screens/hometwo";
import useAuthStore from "./data/Stores/AuthStore";
import IndexTalent from "./talent";
import MainHome from "./pages/home";
import { ToastContainer } from "react-toastify";
import Home2 from "./screens/hometwo";
import SocketClient from "./SocketClient";
import useErrorStore from "./data/Stores/errorstore";
import ModalContainer from "./components/modals/modalcontainer";
import { MainBtn } from "./components/button";
import Home from "./screens/hometwo";

const Routers = () => {
  const { isAuth, userPage } = useAuthStore(),
    { error, clearErrors } = useErrorStore();
  return (
    <>
      {isAuth && <SocketClient />}
      <ToastContainer position="top-right" />
      <Routes>
        <Route
          path="/"
          element={
            isAuth && userPage === "talent" ? (
              <IndexTalent />
            ) : isAuth ? (
              <MainHome />
            ) : (
              <Home2 />
            )
          }
        />
        <Route path="/:page" element={<PageRender />} />
        <Route path="/:page/:id" element={<PageRender />} />
        <Route path="/:page/:id/:step" element={<PageRender />} />
      </Routes>
      {error?.length > 0 && (
        <ModalContainer handleClose={() => clearErrors()}>
          <h2 className="tw-font-bold satoshi tw-text-red-800">Error</h2>
          <div className="downH2 tw-flex tw-flex-col">
            {error?.map((item, i) => (
              <p key={i} className="tw-fw-bold Lexend tw-text-center tw-w-100">
                <span className="fontInherit tw-me-2">
                  {error?.length !== 1 && <>{i + 1}.</>}
                </span>{" "}
                {item?.message || item || null}{" "}
              </p>
            ))}
            <MainBtn
              onClick={clearErrors}
              text={"Close"}
              css="tw-capitalize tw-w-full tw-rounded-xl tw-text-sm tw-font-semibold satoshi tw-bg-[#59FE9C] tw-text-darkblue"
              width="tw-w-full"
              height="tw-h-12"
            />
          </div>
        </ModalContainer>
      )}
    </>
  );
};

export default Routers;
