import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import PrimaryBtn from "../../components/button";
import Logo from "../../components/logo";
import Talent from "../../assets/designers2.png";

const Register = () => {
	const navigate = useNavigate();
	const [show, setShow] = useState();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ settings } = useAuthStore();

	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.lastName) errArr2?.push("Last Name is required");
		if (!state?.firstName) errArr2?.push("First Name is required");
		if (!state?.email) errArr2?.push("Email is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		newState = { ...newState, privilege: "client" };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/activate");
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
				<div
					className="tw-w-full tw-h-full login2"
					style={{
						backgroundImage: `url(${settings?.registerImage?.url || Talent})`,
					}}></div>
				<div className="lg:tw-w-full tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
					<div>
						<NonAuthLogo />
						<strong className="tw-text-4xl bolder satoshi tw-text-primary">
							Sign Up
						</strong>
						<div>
							{" "}
							<small className="tw-text-sm tw-font-normal satoshi tw-text-primary">
								Get started with SoundMap
							</small>
						</div>
						<form onSubmit={handleSubmit} action="" className="tw-mt-8">
							<div className="tw-space-y-4">
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											First Name
										</small>
									</div>
									<input
										type="text"
										className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-md tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										onChange={textChange}
										value={state?.firstName}
										name="firstName"
									/>
								</div>
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Last Name
										</small>
									</div>
									<input
										type="text"
										className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-md tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										onChange={textChange}
										value={state?.lastName}
										name="lastName"
									/>
								</div>
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Email Address
										</small>
									</div>
									<input
										type="email"
										className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-md tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										onChange={textChange}
										value={state?.email}
										name="email"
									/>
								</div>
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Password
										</small>
									</div>
									<div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-md tw-relative">
										<input
											type={show ? "text" : "password"}
											className="tw-w-full tw-h-full tw-rounded-md tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
											onChange={textChange}
											value={state?.password}
											name="password"
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer">
											{show ? <FaEyeSlash /> : <FaEye />}
										</div>
									</div>
								</div>
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Confirm Password
										</small>
									</div>
									<div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-md tw-relative">
										<input
											type={show ? "text" : "password"}
											className="tw-w-full tw-h-full tw-rounded-md tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
											onChange={textChange}
											value={state?.confirmPassword}
											name="confirmPassword"
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer">
											{show ? <FaEyeSlash /> : <FaEye />}
										</div>
									</div>
									<div className="tw-mt-16">
										<PrimaryBtn
											text={"Sign up"}
											width={"tw-w-full tw-mb-5"}
											// disabled={isFormValid}
											bg={"#59FE9C"}
											loading={loading}
											onClick={handleSubmit}
										/>
										<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
											Already have an account?{" "}
											<span
												onClick={() => navigate("/login")}
												className="tw-font-bold tw-underline tw-cursor-pointer">
												Sign In
											</span>
										</h6>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export const NonAuthLogo = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/")}
      className="tw-flex tw-mx-auto tw-mb-5 tw-w-44 tw-cursor-pointer tw-h-12 tw-rounded-xl tw-bg-primary tw-justify-center"
    >
      <Logo />
    </div>
  );
};

export default Register;
