import React, { useState } from "react";
import OtpInput from "react18-otp-input";
import PrimaryBtn from "../components/button";
import { useNavigate } from "react-router-dom";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../data/Stores/AuthStore";

const Activate = () => {
	const navigate = useNavigate(),
		[state, setState] = useState({}),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.otp) errArr2?.push("OTP is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/otp`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/login");
			return;
		}
		setLoading(false);
	};
	console.log({ state });
	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-bg-white tw-flex tw-justify-center tw-items-center">
				<div className="tw-rounded-xl tw-border-primary md:tw-w-1/3 tw-w-11/12 tw-min-h-96 tw-p-6 tw-flex tw-justify-center tw-items-center tw-border tw-shadow-lg tw-bg-white">
					<div>
						<h4 className="tw-text-lg bolder tw-text-primary">
							Enter the OTP received
						</h4>
						<form action="" className="" onSubmit={handleSubmit}>
							<div>
								<OtpInput
									numInputs={5}
									shouldAutoFocus={true}
									isInputNum={true}
									renderSeparator={<span> - </span>}
									renderInput={props => <input {...props} />}
									inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
									containerStyle={{ width: "300px" }}
									value={state?.otp}
									onChange={otp => setState({ ...state, otp })}
									isInputNumber={true}
								/>
							</div>
							<div className="tw-mt-8">
								<PrimaryBtn
									text={"Submit"}
									width={"tw-w-full tw-mb-5"}
									bg={"#59FE9C"}
									loading={loading}
									onClick={handleSubmit}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Activate;
