import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Textinput, { PasswordInput, SelectInput } from "../../components/inputs";
import { motion } from "framer-motion";
import PrimaryBtn from "../../components/button";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { GlobalState } from "../../data/Context";
import { NonAuthLogo } from "../client-register";
import Talent from "../../assets/designers2.png";

const Register = () => {
	const navigate = useNavigate();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	const { countryState } = useContext(GlobalState),
		items = useLocation()?.state,
		{ settings } = useAuthStore();

	useEffect(() => {
		if (items) setState({ ...state, ...items });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.lastName) errArr2?.push("Last Name is required");
		if (!state?.firstName) errArr2?.push("First Name is required");
		if (!state?.email) errArr2?.push("Email is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		newState = { ...newState, privilege: "creative" };

		let { errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user?prevalidate=step-one`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);

		navigate("/talent-register/skills", { state });
	};

	return (
		<div>
			<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
				<div
					className="tw-w-full tw-h-full login2"
					style={{
						backgroundImage: `url(${
							settings?.registerImage?.url || Talent
						})`,
					}}></div>
				<motion.div
					className="m-auto w-100 h-100"
					style={{
						maxWidth: "550px",
					}}
					initial={{
						x: 250,
					}}
					animate={{
						x: 0,
					}}
					transition={{
						duration: 1,
						delay: 1,
						type: "tween",
					}}>
					<div className="lg:tw-w-full tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
						<div>
							<NonAuthLogo />
							<strong className="tw-text-4xl bolder satoshi tw-text-primary">
								Sign Up
							</strong>

							<form onSubmit={handleSubmit} action="" className="tw-mt-8">
								<div className="tw-space-y-4">
									<Textinput
										value={state?.firstName}
										onChange={textChange}
										name="firstName"
										label={"First Name"}
									/>
									<Textinput
										label={"Last Name"}
										value={state?.lastName}
										onChange={textChange}
										name="lastName"
									/>
									<Textinput
										label={"Phone Number"}
										type={"tel"}
										value={state?.telephone}
										onChange={textChange}
										name="telephone"
									/>
									<Textinput
										label={"Email Address"}
										value={state?.email}
										onChange={textChange}
										name="email"
										type="email"
									/>
									<SelectInput
										value={state?.stateOfResidence}
										onChange={textChange}
										name="stateOfResidence"
										label={"State of Residence"}
										options={countryState}
									/>
									<PasswordInput
										label={"Password"}
										value={state?.password}
										onChange={textChange}
										name="password"
									/>
									<PasswordInput
										label={"Confirm Password"}
										value={state?.confirmPassword}
										onChange={textChange}
										name="confirmPassword"
									/>
								</div>
								<div className="tw-mt-">
									{/* <button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
										Next
									</button> */}
									<PrimaryBtn
										text={"Next"}
										width={"tw-w-full tw-mb-5 tw-mt-12 tw-bg-light"}
										// disabled={isFormValid}
										bg={"#59FE9C"}
										loading={loading}
										onClick={handleSubmit}
									/>
									<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
										Already have an account?{" "}
										<span
											onClick={() => navigate("/login")}
											className="tw-font-bold tw-underline tw-cursor-pointer">
											Sign In
										</span>
									</h6>
								</div>
							</form>
						</div>
					</div>
				</motion.div>
			</section>
			<Footer />
		</div>
	);
};

export default Register;
