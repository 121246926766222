import React, { useState, useEffect } from "react";
import ModalContainer from "../../components/modals/modalcontainer";
import Textinput, { ImageInput, SelectInput } from "../../components/inputs";
import PrimaryBtn from "../../components/button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import useInterestStore from "../../data/Stores/intereststore";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const EditProfilePriceModal = ({
	handleClose,
	handleModal,
	state,
	user,
	textChange,
	loading,
	setState,
}) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Price per show
					</h2>
					<form action="" className="tw-mt-6">
						{/* <Textinput
							label={"New Price"}
							value={state?.serviceFee || user?.serviceFee}
							onChange={textChange}
							name="serviceFee"
						/> */}
						<div>
							<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
								New Price
							</small>
						</div>
						<NumericFormat
							prefix={"(NGN) "}
							suffix={""}
							className="lg:tw-w-96 tw-w-full tw-pl-6 tw-h-12 tw-rounded-md tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
							value={state?.serviceFee || user?.serviceFee}
							placeholder={"(NGN) 2,000"}
							displayType="input"
							thousandSeparator={true}
							onValueChange={val =>
								setState({ ...state, serviceFee: val?.floatValue })
							}
							min={0}
							inputMode={"decimal"}
							renderText={(value, props) => <span {...props}>{value}</span>}
							allowNegative={false}
						/>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Edit Price"}
								onClick={handleModal}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditDescription = ({
	handleClose,
	handleModal,
	state,
	user,
	textChange,
	setState,
	loading,
}) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Description
					</h2>
					<form action="" className="tw-mt-6">
						<ReactQuill
							theme="snow"
							value={state?.bio || user?.bio}
							name="bio"
							onChange={e => setState({ ...state, bio: e })}
						/>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Edit Description"}
								onClick={handleModal}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditCategory = ({ handleClose, handleModal }) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Price per show
					</h2>
					<form action="" className="tw-mt-6">
						<SelectInput
							label={"Choose new Category"}
							options={["Singer", "artist"]}
						/>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Add Category"}
								onClick={handleModal}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditInterestsModal = ({
	handleClose,
	handleModal,
	loading,
	setState,
	state,
}) => {
	let [datum, setDatum] = useState(null),
		{ user } = useAuthStore(),
		{ data, getInterest } = useInterestStore();

	useEffect(() => {
		let newData = data;
		if (user?.interests) {
			let newD = [...newData?.docs];
			if (user?.interests?.length > 0) {
				for (let i = 0; i < user?.interests.length; i++) {
					const element = user?.interests[i];

					newD = newD?.map(it => {
						if (it?._id === element?._id) {
							return { ...it, state: true };
						} else {
							return it;
						}
					});
				}
				// console.log({ newD });
			}
			setDatum({ ...newData, docs: newD });
		} else {
			setDatum(newData);
		}
	}, [data, user]);

	const toggleState = id => {
		const selected = datum?.docs?.find(item => item._id === id);
		let newArray = datum?.docs?.map(obj => {
			if (obj === selected) {
				return { ...obj, state: !obj.state };
			} else {
				return obj;
			}
		});
		// console.log({ newArray });
		setDatum({ ...datum, docs: newArray });
	};

	useEffect(() => {
		if (user?.category?.[0]?._id)
			apiCall(
				"get",
				`/api/v1/category/interest?pagination=not&category=${user?.category?.[0]?._id}`,
				null,
				getInterest
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.category?.[0]?._id]);

	return (
		<div>
			{" "}
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Price per show
					</h2>

					<form action="" className="tw-mt-6">
						<div className="tw-flex tw-flex-wrap  tw-gap-3">
							{datum?.docs?.map((it, i) => (
								<div
									key={i}
									onClick={() => toggleState(it?._id)}
									className={`tw-h-10 tw-px-6 tw-capitalize tw-flex tw-items-center tw-text-sm tw-font-medium satoshi tw-justify-center tw-border-[0.4px] tw-border-white tw-rounded-full tw-cursor-pointer ${
										it?.state
											? "tw-bg-primary tw-text-[#F3F5F5]"
											: "tw-text-primary tw-bg-[#F3F5F5]"
									}`}>
									{it?.title || it}
								</div>
							))}
						</div>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Add Interests"}
								onClick={e => {
									e?.preventDefault();
									let filterer = datum?.docs?.filter(it => it?.state);
									if (!filterer?.length === 0) return;
									let interests = filterer?.map(it => it?._id);
									setState({
										...state,
										interests,
									});
									handleModal(e, { interests });
								}}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditCertificates = ({
	handleClose,
	handleModal,
	setState,
	state,
	user,
	loading,
}) => {
	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		// if (!file.type.includes("image"))
		// return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows.certificate = file;
				return newRows;
			});
		}
	};

	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Add new certifite
					</h2>
					<form action="" className="tw-mt-6">
						<ImageInput
							label={"Select the certificate"}
							width={"tw-w-full"}
							name={"certificate"}
							state={state?.certificate || user?.certificate}
							onChange={handleChangeImage}
						/>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Add Certificate"}
								onClick={handleModal}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditBankDetails = ({
	handleClose,
	handleModal,
	user,
	loading,
	state,
	setState,
	textChange,
}) => {
	let { setBank, setAllBank, bank, allbanks } = useAuthStore();
	useEffect(() => {
		apiCall("get", "/api/v1/user/manage-bank", null, setBank);
		apiCall("get", "/api/v1/user/bank-list", null, setAllBank);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	console.log({ state });
	useEffect(() => {
		if (state?.bankCode && state?.accountNumber >= 10) {
			let newer = async () => {
				let { response } = await apiCall(
					"put",
					`/api/v1/user/manage-bank`,
					state
				);
				if (response) {
					setState({
						...state,
						accountName: response?.data?.account_name || response?.account_name,
						bankName: allbanks?.find(it => it?.code === state?.bankCode)?.name,
					});
				}
			};
			newer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.bankCode, state?.accountNumber]);

	// accountNumber, bankCode, bankName
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Description
					</h2>
					<form action="" className="tw-mt-6">
						<div className="tw-space-y-4">
							<SelectInput
								label={"Bank name"}
								value={state?.bankCode || bank?.bankCode}
								onChange={textChange}
								name="bankCode"
								options={allbanks}
							/>
							<Textinput
								label={"Account number"}
								value={state?.accountNumber || bank?.accountNumber}
								onChange={textChange}
								name="accountNumber"
								type={"tel"}
							/>
							<Textinput
								onChange={textChange}
								label={"Account name"}
								readOnly
								value={state?.accountName || bank?.accountName}
								name="accountName"
							/>
						</div>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Edit Details"}
								onClick={e => {
									if (!state?.accountName) return;
									handleModal(e);
								}}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditProfileName = ({
	handleClose,
	handleModal,
	user,
	loading,
	state,
	textChange,
}) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Edit Name
					</h2>
					<form action="" className="tw-mt-6">
						<div className="tw-space-y-4">
							<Textinput
								label={"First name"}
								value={state?.firstName || user?.firstName}
								onChange={textChange}
								name="firstName"
							/>
							<Textinput
								onChange={textChange}
								label={"Last name"}
								value={state?.lastName || user?.lastName}
								name="lastName"
							/>
						</div>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Edit Details"}
								onClick={handleModal}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const EditProfilePicture = ({
	handleClose,
	handleModal,
	setState,
	state,
	user,
	loading,
}) => {
	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows.image = file;
				return newRows;
			});
		}
	};

	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-5">
					<h2 className="tw-text-2xl tw-font-bold tw-capitalize tw-text-primary satoshi">
						Add profile image
					</h2>
					<form action="" className="tw-mt-6">
						<ImageInput
							label={"Select the picture"}
							width={"tw-w-full"}
							name={"image"}
							state={state?.image || user?.image}
							onChange={handleChangeImage}
						/>
						<div className="tw-mt-8 tw-flex tw-justify-end">
							<PrimaryBtn
								bg={"#59FE9C"}
								text={"Add Picture"}
								onClick={handleModal}
								loading={loading}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default EditProfilePriceModal;
