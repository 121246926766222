import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const PaymentStore = set => ({
	data: null,
	status: "",
	getPayment: payload => {
		set({ data: payload?.data || payload }, false, "getPayment");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutPayment: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const usePaymentStore = create(
	devtools(
		persist(PaymentStore, {
			name: "payment",
		})
	)
);

export default usePaymentStore;
