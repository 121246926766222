import React from "react";
import ReactPaginate from "react-paginate";
import PrimaryBtn from "./button";

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<div className="tw-flex tw-justify-center tw-items-center tw-py-10">
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			renderOnZeroPageCount={null}
			pageClassName="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xl tw-font-medium manrope tw-border tw-border-main tw-text-main tw-mx-2 tw-cursor-pointer"
			className="tw-flex tw-items-center tw-justify-center"
			previousClassName="tw-hidden"
			nextClassName="tw-hidden"
			activeClassName="tw-bg-[#002F19] tw-text-white"
		/>
	</div>
);
export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<PrimaryBtn
					onClick={handleLoadMore}
					text={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="tw-mx-auto"
					type={"button"}
					buttonType={"primary"}
					width={"w"}
				/>
			)}
		</>
	);
};
