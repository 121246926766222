import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";
import { Dialog } from "@headlessui/react";
import { X } from "lucide-react";

const YouTubeModalPlayer = ({ videoUrl, isOpen, toggleOpen }) => {
  const [isReady, setIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleReady = () => {
    setIsReady(true);
    setHasError(false);
  };

  const handleError = () => {
    setHasError(true);
    setIsReady(false);
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <Dialog
        open={isOpen}
        onClose={toggleOpen}
        className="tw-relative tw-z-50"
      >
        <div
          className="tw-fixed tw-inset-0 tw-bg-black/70"
          aria-hidden="true"
        />

        <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-p-4">
          <Dialog.Panel className="tw-w-full tw-max-w-3xl tw-rounded tw-bg-white tw-overflow-hidden">
            <div className="tw-relative tw-pt-[56.25%] tw-bg-gray-200">
              {!isReady && !hasError && (
                <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                  <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-b-2 tw-border-gray-900"></div>
                </div>
              )}
              {hasError && (
                <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-text-red-600">
                  Error loading video. Please try again.
                </div>
              )}
              <ReactPlayer
                url={videoUrl}
                width="100%"
                height="100%"
                style={{ position: "absolute", top: 0, left: 0 }}
                controls
                playing={isOpen}
                onReady={handleReady}
                onError={handleError}
              />
            </div>
            <button
              onClick={toggleOpen}
              className="tw-absolute tw-top-2 tw-right-2 tw-text-white tw-bg-black/50 tw-p-1 tw-rounded-full tw-hover:bg-black/70 tw-transition-colors"
            >
              <X size={24} />
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default YouTubeModalPlayer;
