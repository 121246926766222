import React from "react";
import Header from "../components/header";
import PrimaryBtn from "../components/button";
import Footer from "../components/footer";

const Orders = () => {
  const pending = [
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
  ];
  const accepted = [
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
  ];
  const completed = [
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
    {
      title: "lekki live show",
      desc: "Osectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conse",
    },
  ];
  return (
    <div>
      <div className="tw-min-h-screen tw-w-full tw-bg-white tw-pt-8">
        <Header bg={"#002F19"} />
        <div className="tw-my-16 container">
          <h1 className="lg:tw-text-xm tw-font-bold tw-text-primary satoshi">
            Orders
          </h1>
          <div className="tw-mt-8 tw-grid lg:tw-grid-cols-3 tw-gap-5">
            <div>
              <h5 className="tw-text-xs satoshi tw-font-bold tw-text-primary">
                Pending <span className="tw-font-normal">(7)</span>
              </h5>
              <div className="tw-mt-6 tw-space-y-3">
                {pending?.map((it) => (
                  <div className="tw-h-40 tw-w-full tw-border-[0.5px] tw-border-[#7B87944D] tw-rounded-xl tw-p-4">
                    <strong className="tw-text-base tw-capitalize tw-font-medium satoshi tw-text-primary">
                      {it?.title}
                    </strong>
                    <div>
                      <p className="tw-text-xs tw-font-normal satoshi tw-text-primary">
                        {it?.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h5 className="tw-text-xs satoshi tw-font-bold tw-text-primary">
                Accepted <span className="tw-font-normal">(7)</span>
              </h5>
              <div className="tw-mt-6 tw-space-y-3">
                {accepted?.map((it) => (
                  <div className="tw-h-40 tw-w-full tw-border-[0.5px] tw-border-[#7B87944D] tw-rounded-xl tw-p-4">
                    <strong className="tw-text-base tw-capitalize tw-font-medium satoshi tw-text-primary">
                      {it?.title}
                    </strong>
                    <div>
                      <p className="tw-text-xs tw-font-normal satoshi tw-text-primary">
                        {it?.desc}
                      </p>
                    </div>
                    <div className="tw-flex tw-mt-4 tw-justify-end">
                      <PrimaryBtn
                        bg={"#E4FFEF"}
                        color={"black"}
                        text={"Complete"}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h5 className="tw-text-xs satoshi tw-font-bold tw-text-primary">
                Completed <span className="tw-font-normal">(7)</span>
              </h5>
              <div className="tw-mt-6 tw-space-y-3">
                {completed?.map((it) => (
                  <div className="tw-h-40 tw-w-full tw-border-[0.5px] tw-border-[#7B87944D] tw-rounded-xl tw-p-4">
                    <strong className="tw-text-base tw-capitalize tw-font-medium satoshi tw-text-primary">
                      {it?.title}
                    </strong>
                    <div>
                      <p className="tw-text-xs tw-font-normal satoshi tw-text-primary">
                        {it?.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Orders;
