import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";

const Register = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState();
  return (
    <div>
      <section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
        <div className="tw-w-full tw-h-full login"></div>
        <div className="lg:tw-w-full tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
          <div>
            <h1 className="tw-text-4xl tw-font-[900] satoshi tw-text-primary">
              Sign Up
            </h1>
       
            <form action="" className="tw-mt-8">
              <div className="tw-space-y-4">
                <div>
                  <div>
                    <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                      First Name
                    </small>
                  </div>
                  <input
                    type="text"
                    className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-2xl tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
                  />
                </div>
                <div>
                  <div>
                    <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                      Last Name
                    </small>
                  </div>
                  <input
                    type="text"
                    className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-2xl tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
                  />
                </div>
                <div>
                  <div>
                    <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                      Email Address
                    </small>
                  </div>
                  <input
                    type="text"
                    className="lg:tw-w-96 tw-w-80 tw-h-12 tw-rounded-2xl tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
                  />
                </div>
                <div>
                  <div>
                    <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                      Password
                    </small>
                  </div>
                  <div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-2xl tw-relative">
                    <input
                      type={show ? "text" : "password"}
                      className="tw-w-full tw-h-full tw-rounded-2xl tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
                    />
                    <div
                      onClick={() => setShow(!show)}
                      className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer"
                    >
                      {show ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
                      Confirm Password
                    </small>
                  </div>
                  <div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-2xl tw-relative">
                    <input
                      type={show ? "text" : "password"}
                      className="tw-w-full tw-h-full tw-rounded-2xl tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
                    />
                    <div
                      onClick={() => setShow(!show)}
                      className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer"
                    >
                      {show ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  <div className="tw-mt-16">
                    <button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
                      Sign up
                    </button>
                    <h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
                      Already have an account?{" "}
                      <span
                        onClick={() => navigate("/login")}
                        className="tw-font-bold tw-underline tw-cursor-pointer"
                      >
                        Sign In
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Register;
