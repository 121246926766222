import React from "react";
import useAuthStore from "../data/Stores/AuthStore";
import { useNavigate } from "react-router-dom";

const Client = () => {
  const { logout, resetUserPage } = useAuthStore(),
    navigate = useNavigate(),
    handleLogout = () => {
      resetUserPage();
      logout();
      navigate("/");
    };
  return <div onClick={handleLogout}>katyowek</div>;
};

export default Client;
