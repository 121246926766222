import React, { useState } from "react";
import OtpInput from "react18-otp-input";
import PrimaryBtn from "../components/button";
import { useNavigate } from "react-router-dom";

const Otp = () => {
	const navigate = useNavigate(),
		[state, setState] = useState();
	const handleSubmit = e => {
		e?.preventDefault();
		navigate("/change-password", { state });
	};
	console.log({ state });
	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-bg-white tw-flex tw-justify-center tw-items-center">
				<div className="tw-rounded-xl tw-border-primary md:tw-w-1/3 tw-w-11/12 tw-min-h-96 tw-p-6 tw-flex tw-justify-center tw-items-center tw-border tw-shadow-lg tw-bg-white">
					<div>
						<h4 className="tw-text-lg bolder tw-text-primary">
							Enter the OTP received
						</h4>
						<form action="" className="" onSubmit={handleSubmit}>
							<div>
								<OtpInput
									numInputs={6}
									shouldAutoFocus={true}
									isInputNum={true}
									renderSeparator={<span> - </span>}
									renderInput={props => <input {...props} />}
									inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
									containerStyle={{ width: "300px" }}
									value={state?.otp}
									onChange={otp => setState({ ...state, otp })}
									isInputNumber={true}
								/>
							</div>
							<div className="tw-mt-8">
								<PrimaryBtn
									text={"Submit"}
									width={"tw-w-full tw-mb-5"}
									bg={"#59FE9C"}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Otp;
