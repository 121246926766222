import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import { ImageInput, SkillsInput } from "../../components/inputs";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import useAuthStore from "../../data/Stores/AuthStore";
import Talent from "../../assets/designers2.png";

const Skills = () => {
	return (
		<div>
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		items = useLocation()?.state,
		{ settings } = useAuthStore(),
		handleSubmit = e => {
			e?.preventDefault();
			if (!state?.image) return toast.info("Profile Picture is required");

			navigate("/talent-register/preferences", { state });
		};

	useEffect(() => {
		if (items) setState({ ...state, ...items });
		else navigate(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		// if (!file.type.includes("image"))
		// return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows.certificate = file;
				return newRows;
			});
		}
	};

	let handleChangeImage2 = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows.image = file;
				return newRows;
			});
		}
	};

	// console.log({ state });

	return (
		<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
			<div
				className="tw-w-full tw-h-full login2"
				style={{
					backgroundImage: `url(${settings?.registerImage?.url || Talent})`,
				}}></div>
			<div className="lg:tw-w-full tw-w-11/12 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
				<div>
					<strong className="tw-text-4xl bolder satoshi tw-text-primary">
						<span
							className="tw-me-3 tw-inline-flex tw-cursor-pointer"
							onClick={() => {
								navigate("/talent-register", { state });
							}}>
							<IoArrowUndoCircleOutline size={24} />
						</span>
						Skills and Expertise
					</strong>

					<form onSubmit={handleSubmit} action="" className="">
						<div className="tw-space-y-4">
							<ImageInput
								label={"Upload Profile Picture"}
								name={"image"}
								state={state?.image || items?.image}
								onChange={handleChangeImage2}
							/>
							<ImageInput
								label={"Upload any certificate"}
								name={"certificate"}
								state={state?.certificate || items?.certificate}
								onChange={handleChangeImage}
							/>
							<div>
								<SkillsInput
									label={"Upload link to your work"}
									value={state?.linkToWork}
									onChange={textChange}
									name="linkToWork"
								/>
								<small className="tw-text-xs tw-pl-6 tw-text-black tw-font-normal satoshi">
									This is a sample work that can be viewed by the client
								</small>
							</div>
							<SkillsInput
								label={"Instagram handle"}
								value={state?.instagramHandle}
								onChange={textChange}
								name="instagramHandle"
							/>
							<SkillsInput
								label={"Twitter handle"}
								value={state?.twitterHandle}
								onChange={textChange}
								name="twitterHandle"
							/>
						</div>
						<div className="tw-mt-">
							<button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
								Next
							</button>
							<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
								Already have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-underline tw-cursor-pointer">
									Sign In
								</span>
							</h6>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Skills;
