import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./AuthStore";

const OrdersStore = set => ({
	data: null,
	status: "",
	getSearchOrders: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchOrders"
		);
	},
	getSearch: payload => {
		set({ search: payload?.data || payload }, false, "getSearch");
	},
	resetOrdersSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getPendingOrders: payload => {
		set({ pending: payload?.data || payload }, false, "getPendingOrders");
	},
	getCompletedOrders: payload => {
		set({ completed: payload?.data || payload }, false, "getCompletedOrders");
	},
	getOngoingOrders: payload => {
		set({ ongoing: payload?.data || payload }, false, "getOngoingOrders");
	},
	getApprovedOrders: payload => {
		set({ approved: payload?.data || payload }, false, "getApprovedOrders");
	},
	getHomePendingOrders: payload => {
		set(
			{ homepending: payload?.data || payload },
			false,
			"getHomePendingOrders"
		);
	},
	getOrders: payload => {
		set({ data: payload?.data || payload }, false, "getOrders");
	},
	getAllOrders: payload => {
		set({ allData: payload?.data || payload }, false, "getAllOrders");
	},
	addOrders: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addOrders"
		);
	},
	deleteOrders: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				homepending: {
					...state?.homepending,
					docs: DeleteData(state?.homepending?.docs, data),
					totalDocs: state?.homepending?.totalDocs
						? state?.homepending?.totalDocs - 1
						: 0,
					docsTotal: state?.homepending?.docsTotal
						? state?.homepending?.docsTotal - 1
						: 0,
				},
				pending: {
					...state?.pending,
					docs: DeleteData(state?.pending?.docs, data),
					totalDocs: state?.pending?.totalDocs
						? state?.pending?.totalDocs - 1
						: 0,
					docsTotal: state?.pending?.docsTotal
						? state?.pending?.docsTotal - 1
						: 0,
				},
				status: "deleted",
			}),
			false,
			"deleteOrders"
		);
	},
	updateOrders: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editOrders"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutOrders: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useOrdersStore = create(
	devtools(
		persist(OrdersStore, {
			name: "orders",
		})
	)
);

export default useOrdersStore;
