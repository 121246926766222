import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/header";
import Img from "../../assets/artist.png";
import Artist1 from "../../assets/artist1.png";
import Star from "../../assets/star.svg";
import Footer from "../../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import useUserStore from "../../data/Stores/userstore";
import { LoadMore, MainPaginate } from "../../components/Pagination";
import { GlobalState } from "../../data/Context";
import { createMarkup } from "../../talent/edit-profile";

export const MainArtists = () => {
	return (
		<div>
			<section className="tw-bg-white tw-min-h-screen tw-w-full tw-py-12">
				<Header bg={"#002F19"} />
				<MainSectionArtists />
				<MainArtistsSection />
				<Footer />
			</section>
		</div>
	);
};

const Artists = () => <MainArtists />;

export const MainSectionArtists = () => {
	const { state } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="tw-mt-10 container">
			<div className="tw-grid lg:tw-grid-cols-2 tw-justify-between tw-items-center">
				<div>
					{" "}
					<h1 className="tw-text-3xl bolder satoshi tw-text-primary">
						{state?.title || "Artists"}
					</h1>
					<p className="tw-text-base tw-font-normal tw-text-primary satoshi tw-w-96">
						{state?.description ||
							`"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua`}
					</p>
				</div>
				<div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-w-full tw-h-20">
					<div className="tw-w-full">
						<h2 className="tw-text-4xl tw-text-center tw-font-bold tw-text-primary satoshi">
							{state?.artists}+
						</h2>
						<p className="tw-text-base tw-text-center tw-font-normal satoshi tw-text-primary">
							Talents
						</p>
					</div>
					<div className="tw-w-full">
						<h2 className="tw-text-4xl tw-text-center tw-font-bold tw-text-primary satoshi">
							{state?.orders}+
						</h2>
						<p className="tw-text-base tw-text-center tw-font-normal satoshi tw-text-primary">
							Orders
						</p>
					</div>
					<div className="tw-w-full tw-hidden">
						<h2 className="tw-text-4xl tw-text-center tw-font-bold tw-text-primary satoshi">
							50+
						</h2>
						<p className="tw-text-base tw-text-center tw-font-normal satoshi tw-text-primary">
							Lorem ipsum do
						</p>
					</div>
				</div>
			</div>
			<div className="tw-mt-14 tw-hidden">
				<img src={state?.image?.url || Img} alt="" className="tw-w-full" />
			</div>
		</div>
	);
};

export const MainArtistsSection = () => {
	const navigate = useNavigate();
	const { isAuth, setUserPage } = useAuthStore();
	const { numberWithCommas, nairaSign } = useContext(GlobalState);
	// const Arr = [
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// 	{
	// 		name: "bola kenny",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid.....",
	// 		price: "₦16,000 ",
	// 	},
	// ];
	const handleClick = i => {
		if (isAuth) {
			navigate(`/artists/${i?.slug || i}`, { state: i });
		} else {
			setUserPage("client");
			navigate("/login");
		}
	};

	let [datum, setDatum] = useState(null),
		{ isFound, mainSearch, data, getUsers } = useUserStore(),
		{ state } = useLocation();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		if (state)
			apiCall(
				"get",
				`/api/v1/user/manage-creatives?privilege=creative${
					state ? `&category=${state?._id}` : ""
				}`,
				null,
				getUsers
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[search] = useState(""),
		[loading, setLoading] = useState(null),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/user/manage-creatives?privilege=creative&limit=${
				datum?.limit * datum?.nextPage
			}${search ? `&search=${search}` : ""}${
				state ? `&category=${state?._id}` : ""
			}`,
			null,
			getUsers
		);
		setLoading(false);
	};

	return (
		<section className="tw-w-full tw-bg-white tw-mt-16 tw-py-20">
			<div className="container">
				<div className="tw-w-full tw-grid lg:tw-grid-cols-4 tw-gap-6">
					{currentItems?.map((it, i) => (
						<div
							onClick={() => handleClick(it)}
							key={i}
							className="tw-min-h-96 tw-border tw-cursor-pointer tw-border-[#E2E2E2] tw-rounded-lg">
							<img
								src={it?.image?.url || Artist1}
								alt=""
								className="lg:tw-h-52 tw-w-full tw-rounded-t-lg"
							/>
							<div className="tw-h-44 tw-w-full tw-relative tw-rounded-b-lg tw-p-4">
								<h4 className="tw-text-xl tw-capitalize tw-font-bold satoshi tw-text-primary">
									{it?.firstName} {it?.lastName}
								</h4>
								<img src={Star} alt="" className="" />
								{/* <p className="tw-text-xs tw-mt-4 tw-w-5/6 tw-font-normal satoshi tw-text-primary">
									{it?.bio}
								</p> */}
								<p
									className="tw-text-xs tw-mt-4 tw-w-5/6 tw-font-normal satoshi tw-text-primary tw-line-clamp-3"
									dangerouslySetInnerHTML={createMarkup(it?.bio || "")}
								/>
								<div className="tw-absolute tw-bottom-2">
									<h5 className="tw-text-base tw-font-medium satoshi tw-text-primary">
										{nairaSign}{" "}
										{numberWithCommas(Number(it?.serviceFee || 0).toFixed(2))}{" "}
										{state?.suffix || `Per show`}
									</h5>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="tw-mt-4 tw-flex tw-justify-center">
					<LoadMore
						next={page === pageCount && datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</section>
	);
};

export default Artists;
