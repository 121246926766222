import React from "react";
import Logo from "./logo";
import Facebook from "../assets/facebook.svg";
import Youtube from "../assets/youtube.svg";
import { useNavigate } from "react-router-dom";
import Instagram from "../assets/icons8-instagram.svg";
import Twitter from "../assets/icons8-twitter.svg";
import Tiktok from "../assets/icons8-tiktok.svg";

const Footer = () => {
  const navigate = useNavigate();
  // const about = [
  //   {
  //     name: "lorem ipsum",
  //     link: "/lorem",
  //   },
  //   {
  //     name: "lorem ipsum",
  //     link: "/lorem",
  //   },
  //   {
  //     name: "lorem ipsum",
  //     link: "/lorem",
  //   },
  //   {
  //     name: "lorem ipsum",
  //     link: "/lorem",
  //   },
  // ];
  return (
    <div>
      <section className="tw-w-full tw-bg-primary tw-py-10">
        <div className="container">
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-border-b tw-border-b-white tw-pb-12 tw-justify-between">
            <div>
              <Logo />
              <p className="tw-text-base tw-font-normal tw-max-w-sm satoshi tw-text-white">
                Soundmap stands as a link between the service seeker and the
                service providers. We provide and bring you vetted and trusted
                talents you might require in diverse areas of production.
              </p>
            </div>
            <div className="tw-flex tw-mt-6 lg:tw-mt-0 tw-gap-8">
              <div>
                <h6
                  onClick={() => navigate("/about-us")}
                  className="tw-text-white tw-cursor-pointer tw-text-sm tw-font-[900] satoshi"
                >
                  About
                </h6>
                {/* <div className="tw-mt-5 tw-space-y-4">
                  {about?.map((it, i) => (
                    <div key={i} className="">
                      <small className="tw-text-base tw-cursor-pointer tw-font-normal satoshi tw-text-white">
                        {it?.name}
                      </small>
                    </div>
                  ))}
                </div> */}
              </div>
              <div>
                <h6
                  onClick={() => navigate("/personal")}
                  className="tw-text-white tw-cursor-pointer tw-text-sm tw-font-[900] satoshi"
                >
                  Personal Development
                </h6>
                {/* <div className="tw-mt-5 tw-space-y-4">
                  {about?.map((it, i) => (
                    <div key={i} className="">
                      <small className="tw-text-base tw-cursor-pointer tw-font-normal satoshi tw-text-white">
                        {it?.name}
                      </small>
                    </div>
                  ))}
                </div> */}
              </div>
              <div>
                <h6
                  onClick={() => navigate("/orders")}
                  className="tw-text-white tw-cursor-pointer tw-text-sm tw-font-[900] satoshi"
                >
                  Orders
                </h6>
              </div>
            </div>
          </div>
          <div className="tw-pt-6">
            <div className="tw-flex tw-justify-end">
              <div className="tw-flex tw-gap-5 tw-items-center">
                <a
                  href="https://www.facebook.com/soundmapafrica?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="" className="tw-h-10" />
                </a>
                <a
                  href="https://www.instagram.com/soundmapafrica/profilecard/?igsh=MW5vM3NkNmp5bjhxYw=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="" className="tw-h-10" />
                </a>
                <a
                  href="https://x.com/soundmapafrica?s=21"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="" className="tw-h-10" />
                </a>
                <a
                  href="https://youtube.com/@soundmapafrica?si=i-eElUO6qWTrw9lJ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Youtube} alt="" className="tw-h-10" />
                </a>
                <a
                  href="https://www.tiktok.com/@soundmapafrica?_t=8qOy0tXDyDd&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Tiktok} alt="" className="tw-h-10" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
