import React from "react";
import { ClipLoader } from "react-spinners";

const PrimaryBtn = ({
	text,
	bg,
	color,
	onClick,
	width,
	disabled,
	...restProps
}) => {
	return (
		<div>
			<button
				disabled={disabled || restProps?.loading}
				onClick={onClick}
				style={{
					background: bg,
					color: color,
					boxShadow: "0px 12px 25px 0px #00000040",
				}}
				className={`tw-h-12 ${width || "tw-px-5"} ${
					(disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				} tw-rounded-xl tw-text-base tw-font-medium satoshi`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const ShadowBtn = ({ text, onClick, ...restProps }) => {
	return (
		<div>
			<button
				onClick={onClick}
				disabled={restProps?.disabled || restProps?.loading}
				style={{
					boxShadow: "0px 12px 25px 0px #00000040",
					background: "#59FE9C",
				}}
				className={`tw-h-12 tw-rounded-xl tw-text-primary tw-w-32 tw-text-base tw-font-medium satoshi ${
					(restProps?.disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				}`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const MainBtn = ({ text, onClick, width, height, ...restProps }) => {
	return (
		<div>
			<button
				onClick={onClick}
				style={{
					background: "#59FE9C",
				}}
				className={`${height || "tw-h-8"} tw-text-primary ${
					width || "tw-px-5"
				} tw-rounded-xl tw-text-base medium ${
					(restProps?.disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				} ${restProps?.css || ""}`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const ChatBtn = ({ text, bg, color, onClick }) => {
	return (
		<div>
			<button
				onClick={onClick}
				style={{
					background: bg,
					color: color,
				}}
				className="tw-h-8 tw-px-5 tw-rounded-xl tw-text-base tw-font-medium satoshi">
				{text}
			</button>
		</div>
	);
};

export const CancelBtn = ({ text, bg, color, onClick }) => {
	return (
		<div>
			<button
				onClick={onClick}
				className="tw-h-12 tw-border-[0.3px] tw-w-32 tw-border-[#7B87944D] tw-rounded-xl tw-bg-white tw-text-sm tw-font-medium tw-text-primary satoshi">
				{text}
			</button>
		</div>
	);
};

export const DeleteBtn = ({ onClick }) => {
	return (
		<div>
			<button
				onClick={onClick}
				className="tw-h-10 tw-w-20 tw-bg-[#FF00001A] tw-rounded-lg tw-text-base tw-font-medium satoshi tw-text-[#FF0000]">
				Delete
			</button>
		</div>
	);
};

export default PrimaryBtn;
