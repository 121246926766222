import React, { useState } from "react";
import PrimaryBtn from "../components/button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useErrorStore from "../data/Stores/errorstore";
import { apiCall } from "../data/Stores/AuthStore";

const ForgotPassword = () => {
	const navigate = useNavigate();

	const init = {
			title: "",
			description: "",
			icon: "",
			color: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.email) errArr2?.push("Email is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/user/reset-password`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/otp");
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-bg-white tw-flex tw-justify-center tw-items-center">
				<div className="tw-rounded-xl tw-border-primary md:tw-w-1/3 tw-w-11/12 tw-min-h-96 tw-p-6 tw-flex tw-justify-center tw-items-center tw-border tw-shadow-lg tw-bg-white">
					<div>
						<h4 className="tw-text-xl bolder tw-text-primary">
							Reset your password
						</h4>
						<form className="" onSubmit={handleSubmit}>
							<div>
								<div>
									<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
										Email Address
									</small>
								</div>
								<input
									type="text"
									name="email"
									className="lg:tw-w-96 tw-pl-6 tw-w-80 tw-h-12 tw-rounded-sm tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
									onChange={textChange}
									value={state?.email}
								/>
							</div>
							<div className="tw-mt-16">
								<PrimaryBtn
									text={"Submit"}
									width={"tw-w-full tw-mb-5"}
									bg={"#59FE9C"}
									loading={loading}
									onClick={handleSubmit}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
