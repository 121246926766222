import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { MainChatSection } from "../talent/chat";

const Chat = () => {
  return (
    <div>
      <div className="tw-w-full tw-min-h-screen tw-pt-8">
        <Header bg={"#002F19"} />
        <MainChatSection />
        <Footer />
      </div>
    </div>
  );
};

export default Chat;
