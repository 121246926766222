import React, { useState } from "react";
import Footer from "../components/footer";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import useGenFetcher from "../data/useGenFetcher";
import useErrorStore from "../data/Stores/errorstore";
import PrimaryBtn from "../components/button";
import { NonAuthLogo } from "./client-register";
import Talent from "../assets/designers2.png";

const Login = () => {
	const navigate = useNavigate();
	const [show, setShow] = useState();
	const { login, settings } = useAuthStore(),
		{ loadUser } = useGenFetcher();

	const init = {
			title: "",
			description: "",
			icon: "",
			color: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.email) errArr2?.push("Email is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		newState = { ...newState, privilege: "client" };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/login`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			login(response);
			loadUser();
			navigate("/");
			return;
		}
		setLoading(false);
	};

	return (
		<section>
			<div className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
				<div
					className="tw-h-full tw-w-full login2 tw-hidden lg:tw-block"
					style={{
						backgroundImage: `url(${settings?.registerImage?.url || Talent})`,
					}}></div>
				<motion.div
					className="m-auto w-100 h-100"
					style={{
						maxWidth: "550px",
					}}
					initial={{
						x: 250,
					}}
					animate={{
						x: 0,
					}}
					transition={{
						duration: 1,
						delay: 1,
						type: "tween",
					}}>
					{" "}
					<div className="lg:tw-w-full tw-w-5/6 tw-mx-auto tw-h-full tw-flex tw-justify-center tw-items-center">
						<div>
							<NonAuthLogo />
							<h1 className="tw-text-4xl tw-font-[900] satoshi tw-text-primary">
								Welcome Back
							</h1>

							<form onSubmit={handleSubmit} action="" className="tw-mt-8">
								<div className="tw-space-y-4">
									<div>
										<div>
											<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
												Email Address
											</small>
										</div>
										<input
											type="text"
											onChange={textChange}
											name="email"
											value={state?.email}
											className="lg:tw-w-96 tw-pl-6 tw-w-80 tw-h-12 tw-rounded-sm tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-primary tw-border-[0.4px] tw-border-white"
										/>
									</div>
									<div>
										<div>
											<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
												Password
											</small>
										</div>
										<div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-md tw-relative">
											<input
												name="password"
												onChange={textChange}
												value={state?.password}
												type={show ? "text" : "password"}
												className="tw-w-full tw-h-full tw-rounded-md tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
											/>
											<div
												onClick={() => setShow(!show)}
												className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer">
												{show ? <FaEyeSlash /> : <FaEye />}
											</div>
										</div>
										<div className="lg:tw-w-96 tw-w-80 tw-flex tw-justify-between tw-items-center tw-mt-4">
											<div className="tw-flex tw-gap-2 tw-items-center">
												<input
													type="checkbox"
													name="remember"
													className="tw-size-5 tw-bg-primary"
													id=""
												/>
												<div className="">
													<small className="tw-text-sm tw-text-primary tw-font-medium satoshi">
														Remember me
													</small>
												</div>
											</div>
											<div
												onClick={() => navigate("/forgotpassword")}
												className="">
												<strong className="tw-cursor-pointer tw-text-sm tw-font-medium satoshi tw-text-primary">
													Forget Password?
												</strong>
											</div>
										</div>
										<div className="tw-mt-16">
											<PrimaryBtn
												text={"Login"}
												width={"tw-w-full tw-mb-5"}
												// disabled={isFormValid}
												bg={"#59FE9C"}
												loading={loading}
												onClick={handleSubmit}
											/>
											<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
												Don't have an account?{" "}
												<span
													onClick={() => navigate("/register")}
													className="tw-font-bold tw-underline tw-cursor-pointer">
													Sign Up
												</span>
											</h6>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</motion.div>
			</div>
			<Footer />
		</section>
	);
};

export default Login;
