import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const NotificationStore = set => ({
	data: null,
	status: "",
	getNotification: payload => {
		set({ data: payload?.data || payload }, false, "getNotification");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutNotification: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useNotificationStore = create(
	devtools(
		persist(NotificationStore, {
			name: "notification",
		})
	)
);

export default useNotificationStore;
