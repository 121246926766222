import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { toast } from "react-toastify";
import useErrorStore from "../../data/Stores/errorstore";
import PrimaryBtn from "../../components/button";
import Talent from "../../assets/designers2.png";

const Terms = () => {
	return (
		<div>
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ state } = useLocation(),
		[terms, setTerms] = useState(false),
		{ settings } = useAuthStore();

	useEffect(() => {
		if (!state) navigate(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.lastName) errArr2?.push("Last Name is required");
		if (!state?.firstName) errArr2?.push("First Name is required");
		if (!state?.email) errArr2?.push("Email is required");
		if (!state?.category) errArr2?.push("Category is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (!state?.image) errArr2?.push("Profile Picture is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		if (state?.certificate) {
			let { response, errArr, errMsg } = await apiCall("file", "/api/v1/file", {
				intendedFile: state?.certificate,
			});
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			newState.certificate = response?.data?.[0];
		}
		if (state?.image) {
			let { response, errArr, errMsg } = await apiCall("file", "/api/v1/file", {
				intendedFile: state?.image,
			});
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			newState.image = response?.data?.[0];
		}

		newState = {
			...newState,
			privilege: "creative",
			category: [state?.category],
		};

		let { errArr, errMsg, response } = await apiCall(
			"post",
			`/api/v1/user`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/activate");
			return;
		}
		setLoading(false);
	};

	return (
		<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
			<div
				className="tw-w-full tw-h-full login2"
				style={{
					backgroundImage: `url(${settings?.registerImage?.url || Talent})`,
				}}></div>
			<div className="tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center">
				<div>
					<div className="">
						<strong className="tw-text-4xl bolder satoshi tw-text-primary">
							Terms and Conditions
						</strong>

						<div>
							<strong className="tw-text-sm tw-font-bold tw-text-primary satoshi">
								The standard Lorem Ipsum passage, used since the 1500s
							</strong>
						</div>
						<p className="tw-text-sm tw-pt-2 tw-font-normal tw-w-full satoshi tw-text-primary">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore eu fugiat
							nulla pariatur. Excepteur sint occaecat cupidatat non proident,
							sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
						<div>
							<strong className="tw-text-sm tw-font-bold tw-text-primary satoshi">
								The standard Lorem Ipsum passage, used since the 1500s
							</strong>
						</div>
						<p className="tw-text-sm tw-font-normal tw-pt-2 tw-w-full satoshi tw-text-primary">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore eu fugiat
							nulla pariatur. Excepteur sint occaecat cupidatat non proident,
							sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
						<div className="tw-mt-6 tw-flex tw-gap-3 tw-items-center">
							<input
								type="checkbox"
								name=""
								className="tw-size-4"
								id=""
								checked={terms}
								onChange={e => setTerms(e?.target?.checked)}
							/>
							<div>
								<small className="tw-text-sm tw-font-medium tw-text-primary satoshi">
									I have read and accepted Terms of Service & Privacy Policy.{" "}
								</small>
							</div>
						</div>
						<div className="tw-mt-12">
							{/* <button
								onClick={handleSubmit}
								className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
								Next
							</button> */}
							<PrimaryBtn
								text={"Sign up"}
								width={"tw-w-full tw-mb-5 tw-bg-light"}
								// disabled={isFormValid}
								bg={"#59FE9C"}
								loading={loading}
								disabled={!terms}
								onClick={handleSubmit}
							/>
							<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
								Already have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-underline tw-cursor-pointer">
									Sign In
								</span>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Terms;
