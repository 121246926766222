import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import PrimaryBtn from "../components/button";
import useErrorStore from "../data/Stores/errorstore";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { apiCall } from "../data/Stores/AuthStore";

const ChangePassword = () => {
	const [show, setShow] = useState(false);

	const navigate = useNavigate(),
		location = useLocation()?.state;

	useEffect(() => {
		if (!location) navigate(-1);
	}, [location, navigate]);

	const init = {
			title: "",
			description: "",
			icon: "",
			color: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.password) errArr2?.push("Email is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state, ...location };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/reset-password`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/login");
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-bg-white tw-flex tw-justify-center tw-items-center">
				<div className="tw-rounded-xl tw-border-primary md:tw-w-1/3 tw-w-11/12 tw-min-h-96 tw-p-6 tw-flex tw-justify-center tw-items-center tw-border tw-shadow-lg tw-bg-white">
					<div>
						<h4 className="tw-text-base bolder tw-text-primary">
							Enter new password
						</h4>
						<form action="" className="">
							<div className="tw-space-y-4">
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Password
										</small>
									</div>
									<div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-md tw-relative">
										<input
											name="password"
											type={show ? "text" : "password"}
											className="tw-w-full tw-h-full tw-rounded-md tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
											value={state?.password}
											onChange={textChange}
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer">
											{show ? <FaEyeSlash /> : <FaEye />}
										</div>
									</div>
								</div>{" "}
								<div>
									<div>
										<small className="tw-text-sm tw-font-medium satoshi tw-text-primary">
											Confirm Password
										</small>
									</div>
									<div className="lg:tw-w-96 tw-w-80 tw-h-12 tw-bg-[#F3F5F5] tw-rounded-md tw-relative">
										<input
											name="confirmPassword"
											type={show ? "text" : "password"}
											className="tw-w-full tw-h-full tw-rounded-md tw-bg-transparent tw-pl-6 tw-text-base tw-font-medium tw-text-primary satoshi"
											value={state?.confirmPassword}
											onChange={textChange}
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-top-4 tw-right-3 tw-cursor-pointer">
											{show ? <FaEyeSlash /> : <FaEye />}
										</div>
									</div>
								</div>
							</div>
							<div className="tw-mt-6">
								<PrimaryBtn
									text={"Submit"}
									width={"tw-w-full tw-mb-5"}
									bg={"#59FE9C"}
									loading={loading}
									onClick={handleSubmit}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
