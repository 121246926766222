import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import About from "../assets/about.png";
import { Features, Testimonial } from "../screens/home";

const AboutUs = () => {
  return (
    <div>
      <section className="tw-bg-white tw-min-h-screen tw-w-full tw-pt-12">
        <Header bg={"#002F19"} />
        <MainSection />
        <Features />
        <Testimonial />
        <Footer />
      </section>
    </div>
  );
};

const MainSection = () => {
  return (
    <div>
      <div className="tw-my-16 container">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
          <div>
            <strong
              data-aos="fade-in-left"
              data-aos-duration="1500"
              className="tw-text-xm bolder tw-text-primary"
            >
              Our Talents
            </strong>
          </div>
          <div
            data-aos="zoom-in-left"
            data-aos-duration="1500"
            className="tw-max-w-sm"
          >
            <small className="tw-text-base tw-text-primary satoshi tw-font-normal">
              Soundmap stands as a link between the service seeker and the
              service providers. We provide and bring you vetted and trusted
              talents you might require in diverse areas of production.
            </small>
          </div>
        </div>
        <div className="tw-mt-14">
          <img
            data-aos="flip-up"
            data-aos-duration="1500"
            src={About}
            alt=""
            className="tw-w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
