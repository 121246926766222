import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowUndoCircleOutline } from "react-icons/io5";
import useInterestStore from "../../data/Stores/intereststore";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import Talent from "../../assets/designers2.png";

const Interests = () => {
	return (
		<div>
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let [datum, setDatum] = useState(null),
		{ data, getInterest } = useInterestStore();

	const init = {},
		[state, setState] = useState(init),
		navigate = useNavigate(),
		items = useLocation()?.state,
		{ settings } = useAuthStore();

	useEffect(() => {
		let newData = data;
		if (items) {
			let newD =
				newData && Array?.isArray(newData?.docs) ? [...newData?.docs] : [];
			if (items?.interests?.length > 0) {
				for (let i = 0; i < items?.interests.length; i++) {
					const element = items?.interests[i];

					newD = newD?.map(it => {
						if (it?._id === element) {
							return { ...it, state: true };
						} else {
							return it;
						}
					});
				}
				// console.log({ newD });
			}
			setDatum({ ...newData, docs: newD });
		} else {
			setDatum(newData);
		}
	}, [data, items]);

	const handleSubmit = e => {
		e?.preventDefault();
		let filterer = datum?.docs?.filter(it => it?.state);
		if (!filterer?.length === 0) return;
		navigate("/talent-register/fees", {
			state: { ...state, interests: filterer?.map(it => it?._id) },
		});
	};

	useEffect(() => {
		if (items) {
			setState({ ...state, ...items });
		} else navigate(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const toggleState = id => {
		const selected = datum?.docs?.find(item => item._id === id);
		let newArray = datum?.docs?.map(obj => {
			if (obj === selected) {
				return { ...obj, state: !obj.state };
			} else {
				return obj;
			}
		});
		// console.log({ newArray });
		setDatum({ ...datum, docs: newArray });
	};

	useEffect(() => {
		if (state?.category)
			apiCall(
				"get",
				`/api/v1/category/interest?pagination=not&category=${state?.category}`,
				null,
				getInterest
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.category]);

	// console.log({ state });

	return (
		<section className="tw-w-full tw-min-h-screen tw-grid lg:tw-grid-cols-2">
			<div
				className="tw-w-full tw-h-full login2"
				style={{
					backgroundImage: `url(${
						settings?.registerImage?.url || Talent
					})`,
				}}></div>
			<div className="lg:tw-w-full tw-w-5/6 tw-mx-auto lg:tw-h-full tw-py-10 tw-flex tw-justify-center tw-items-center">
				<div>
					<strong className="tw-text-4xl bolder satoshi tw-text-primary">
						<span
							className="tw-me-3 tw-inline-flex tw-cursor-pointer"
							onClick={() => {
								navigate("/talent-register/preferences", { state });
							}}>
							<IoArrowUndoCircleOutline size={24} />
						</span>{" "}
						Interest
					</strong>
					<form onSubmit={handleSubmit} action="" className="tw-mt-6">
						<div className="tw-flex tw-flex-wrap  tw-gap-3">
							{datum?.docs?.map((it, i) => (
								<div
									key={i}
									onClick={() => toggleState(it?._id)}
									className={`tw-h-10 tw-px-6 tw-capitalize tw-flex tw-items-center tw-text-sm tw-font-medium satoshi tw-justify-center tw-border-[0.4px] tw-border-white tw-rounded-full tw-cursor-pointer ${
										it?.state
											? "tw-bg-primary tw-text-[#F3F5F5]"
											: "tw-text-primary tw-bg-[#F3F5F5]"
									}`}>
									{it?.title || it}
								</div>
							))}
						</div>
						<div className="tw-mt-10">
							<button className="tw-h-12 tw-bg-light tw-text-darkblue tw-rounded-xl tw-text-base tw-font-medium satoshi tw-mt-12 tw-w-full">
								Next
							</button>
							<h6 className="tw-text-sm tw-text-primary tw-font-medium tw-text-center satoshi">
								Already have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-underline tw-cursor-pointer">
									Sign In
								</span>
							</h6>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Interests;
