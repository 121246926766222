import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { MainSectionCategory } from "../screens/hometwo";

const Categories = () => <MainCategories />;

export const MainCategories = () => {
	return (
		<div>
			<section className="tw-bg-white tw-min-h-screen tw-w-full tw-py-12">
				<Header bg={"#002F19"} />
				<MainSectionCategory />
				<Footer />
			</section>
		</div>
	);
};

export default Categories;
