import { MdAdd, MdClose } from "react-icons/md";
import { GoDash } from "react-icons/go";
import { useEffect, useState } from "react";
import PrimaryBtn from "../components/button";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import { toast } from "react-toastify";
import useErrorStore from "../data/Stores/errorstore";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ScheduleBooking = () => {
  let startMonday = { id: 1, start: "07:00", end: "17:00" },
    startTuesday = { id: 1, start: "07:00", end: "17:00" },
    startWed = { id: 1, start: "07:00", end: "17:00" },
    startThurs = { id: 1, start: "07:00", end: "17:00" },
    startFri = { id: 1, start: "07:00", end: "17:00" },
    startSat = { id: 1, start: "07:00", end: "17:00" },
    startSun = { id: 1, start: "07:00", end: "17:00" },
    [Monday, setMonday] = useState([startMonday]),
    [Tuesday, setTuesday] = useState([startTuesday]),
    [Wednesday, setWednesday] = useState([startWed]),
    [Thursday, setThursday] = useState([startThurs]),
    [Friday, setFriday] = useState([startFri]),
    [Saturday, setSaturday] = useState([startSat]),
    [Sunday, setSunday] = useState([startSun]),
    [MondayShow, setMondayShow] = useState(true),
    [TuesdayShow, setTuesdayShow] = useState(true),
    [WednesdayShow, setWednesdayShow] = useState(true),
    [ThursdayShow, setThursdayShow] = useState(true),
    [FridayShow, setFridayShow] = useState(true),
    [SaturdayShow, setSaturdayShow] = useState(true),
    [SundayShow, setSundayShow] = useState(true),
    days = [
      {
        day: "Monday",
        available: Monday,
        show: MondayShow,
      },
      {
        day: "Tuesday",
        available: Tuesday,
        show: TuesdayShow,
      },
      {
        day: "Wednesday",
        available: Wednesday,
        show: WednesdayShow,
      },
      {
        day: "Thursday",
        available: Thursday,
        show: ThursdayShow,
      },
      {
        day: "Friday",
        available: Friday,
        show: FridayShow,
      },
      {
        day: "Saturday",
        available: Saturday,
        show: SaturdayShow,
      },
      {
        day: "Sunday",
        available: Sunday,
        show: SundayShow,
      },
    ];

  const [loading, setLoading] = useState(false),
    { user, setUser, isAuth } = useAuthStore(),
    navigate = useNavigate();

  useEffect(() => {
    if (user?.availability) {
      let findMon = user?.availability?.times?.find(
        (item) => item?.day === "Monday"
      );
      if (findMon) {
        setMonday(findMon?.available);
        setMondayShow(findMon?.show);
      }
      let findTue = user?.availability?.times?.find(
        (item) => item?.day === "Tuesday"
      );
      if (findTue) {
        setTuesday(findTue?.available);
        setTuesdayShow(findTue?.show);
      }
      let findWed = user?.availability?.times?.find(
        (item) => item?.day === "Wednesday"
      );
      if (findWed) {
        setWednesday(findWed?.available);
        setWednesdayShow(findWed?.show);
      }
      let findThurs = user?.availability?.times?.find(
        (item) => item?.day === "Thursday"
      );
      if (findThurs) {
        setThursday(findThurs?.available);
        setThursdayShow(findThurs?.show);
      }
      let findFri = user?.availability?.times?.find(
        (item) => item?.day === "Friday"
      );
      if (findFri) {
        setFriday(findFri?.available);
        setFridayShow(findFri?.show);
      }
      let findSat = user?.availability?.times?.find(
        (item) => item?.day === "Saturday"
      );
      if (findSat) {
        setSaturday(findSat?.available);
        setSaturdayShow(findSat?.show);
      }
      let findSun = user?.availability?.times?.find(
        (item) => item?.day === "Sunday"
      );
      if (findSun) {
        setSunday(findSun?.available);
        setSundayShow(findSun?.show);
      }
    }
    // setPrice(user?.availability);
  }, [user]);

  useEffect(() => {
    if (!isAuth) navigate(-1);
    if (user?.privilege !== "creative") navigate(-1);
  }, [isAuth, user, navigate]);

  let { returnErrors } = useErrorStore();
  let handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    let newState;
    let newDays = [];
    for (let d = 0; d < days.length; d++) {
      if (!days?.[d]?.show) newDays?.push({ ...days?.[d], available: false });
      else newDays?.push(days?.[d]);
    }
    newState = { times: newDays };

    let { response, errArr, errMsg } = await apiCall(
      "post",
      `/api/v1/user/manage-availability`,
      newState
    );
    // console.log({ response, errArr, errMsg });
    if (errArr) {
      setLoading(false);
      return returnErrors(errArr);
    }
    if (errMsg) {
      setLoading(false);
      return toast.error(errMsg);
    }
    setLoading(false);
    if (response) {
      setUser(response);
      return;
    }
    setLoading(false);
  };

  return (
		<>
			<div>
				<Header bg={"#002F19"} />
				<section className="tw-bg-white tw-min-h-screen tw-w-full tw-pt-12">
					<section className="tw-w-full tw-mb-16">
						<div className="container">
							<div className="lg:tw-w-1/2 tw-mx-auto tw-pt-16">
								<div>
									<div className="tw-text-center">
										<p className="tw-text-2xl tw-font-bold tw-mb-2">
											Set your availability
										</p>
										<p className="tw-text-xl tw-text-gray-400 tw-font-normal tw-mb-4">
											Pick the days and the times when you are typically
											available for a gig.
										</p>
									</div>
									{days.map((day, idx) => (
										<Scheduler
											day={day?.day}
											time={day?.available}
											key={idx}
											show={day?.show}
											setMonday={setMonday}
											setThursday={setThursday}
											setTuesday={setTuesday}
											setWednesday={setWednesday}
											setFriday={setFriday}
											setSaturday={setSaturday}
											setSunday={setSunday}
											setMondayShow={setMondayShow}
											setThursdayShow={setThursdayShow}
											setTuesdayShow={setTuesdayShow}
											setWednesdayShow={setWednesdayShow}
											setFridayShow={setFridayShow}
											setSaturdayShow={setSaturdayShow}
											setSundayShow={setSundayShow}
										/>
									))}
									<div className="tw-flex tw-justify-center tw-mb-20">
										<PrimaryBtn
											bg={"#59FE9C"}
											text={"Save"}
											onClick={handleSubmit}
											loading={loading}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			</div>
		</>
	);
};

export default ScheduleBooking;

export const Scheduler = ({
  day,
  time,
  setMonday,
  setTuesday,
  setWednesday,
  setThursday,
  setFriday,
  setSaturday,
  setSunday,
  setMondayShow,
  setTuesdayShow,
  setWednesdayShow,
  setThursdayShow,
  setFridayShow,
  setSaturdayShow,
  setSundayShow,
  show,
}) => {
  const [timeSelect, setTimeSelect] = useState([]);
  const toggle = () => {
    if (day === "Monday") setMondayShow(!show);
    if (day === "Tuesday") setTuesdayShow(!show);
    if (day === "Wednesday") setWednesdayShow(!show);
    if (day === "Thursday") setThursdayShow(!show);
    if (day === "Friday") setFridayShow(!show);
    if (day === "Saturday") setSaturdayShow(!show);
    if (day === "Sunday") setSundayShow(!show);
  };
  const removeItem = (id) => {
    if (time.length <= 1) {
      if (day === "Monday") setMondayShow(false);
      if (day === "Tuesday") setTuesdayShow(false);
      if (day === "Wednesday") setWednesdayShow(false);
      if (day === "Thursday") setThursdayShow(false);
      if (day === "Friday") setFridayShow(false);
      if (day === "Saturday") setSaturdayShow(false);
      if (day === "Sunday") setSundayShow(false);
    } else {
      let newTimes = time.filter((item) => item.id !== id);
      if (day === "Monday") setMonday(newTimes);
      if (day === "Tuesday") setTuesday(newTimes);
      if (day === "Wednesday") setWednesday(newTimes);
      if (day === "Thursday") setThursday(newTimes);
      if (day === "Friday") setFriday(newTimes);
      if (day === "Saturday") setSaturday(newTimes);
      if (day === "Sunday") setSunday(newTimes);
    }
  };

  const addItem = () => {
    let newTimes = [...time, { id: time.length + 1, start: "", end: "" }];
    if (day === "Monday") setMonday(newTimes);
    if (day === "Tuesday") setTuesday(newTimes);
    if (day === "Wednesday") setWednesday(newTimes);
    if (day === "Thursday") setThursday(newTimes);
    if (day === "Friday") setFriday(newTimes);
    if (day === "Saturday") setSaturday(newTimes);
    if (day === "Sunday") setSunday(newTimes);
  };

  useEffect(() => {
    let timerange = splitAvailabilityTime("00:00", "24:00");
    setTimeSelect(timerange);
  }, []);

  return (
    <div className="tw-block tw-max-w-sm tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow tw-mx-auto tw-mb-4">
      <div className="tw-flex tw-items-center tw-justify-between tw-p-6">
        <h5 className="tw-mb-2 tw-text-lg tw-font-medium tw-tracking-tight tw-text-gray-900">
          {day}
        </h5>
        <label className="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
          <input
            type="checkbox"
            checked={show}
            className="tw-sr-only tw-peer peer"
            onChange={toggle}
          />
          <div className="tw-w-14 tw-h-7 tw-bg-gray-200 peer-focus:tw-outline-none peer-focus:tw-ring-4 peer-focus:tw-ring-blue-300 dark:peer-focus:tw-ring-blue-800 tw-rounded-full peer dark:tw-bg-gray-700 peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-left-[4px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-6 after:tw-w-6 after:tw-transition-all tw-border-gray-600 peer-checked:tw-bg-blue-600"></div>
        </label>
      </div>
      {show &&
        time.map((item, i) => (
          <div
            className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-gap-x-2 tw-border-t-2 tw-border-gray-200"
            key={i}
          >
            {time?.length > 1 ? (
              <div
                className="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-gray-50"
                onClick={() => removeItem(item.id)}
              >
                <MdClose />
              </div>
            ) : (
              <div></div>
            )}
            <select
              id="countries"
              onChange={(e) => {
                let times = time,
                  findTime = times?.find((d) => d?.id === item?.id);
                findTime.start = e.target?.value;
                let newTimes = times?.map((a) =>
                  a?.id === findTime?.id ? findTime : a
                );
                if (day === "Monday") setMonday(newTimes);
                if (day === "Tuesday") setTuesday(newTimes);
                if (day === "Wednesday") setWednesday(newTimes);
                if (day === "Thursday") setThursday(newTimes);
                if (day === "Friday") setFriday(newTimes);
                if (day === "Saturday") setSaturday(newTimes);
                if (day === "Sunday") setSunday(newTimes);
              }}
              value={time?.[item?.id - 1]?.start}
              className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-full tw-font-bold tw-w-fit focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-p-2.50"
            >
              {timeSelect?.slice(0, timeSelect?.length - 1)?.map((item, i) => (
                <option key={i} value={item}>
                  {Number(item?.split(":")[0]) < 12 ? (
                    <>{item} AM</>
                  ) : Number(item?.split(":")[0]) > 12 ? (
                    <>
                      {padZero(Number(item?.split(":")[0]) % 12)}:
                      {item?.split(":")[1]} PM
                    </>
                  ) : (
                    <>{item} PM</>
                  )}
                </option>
              ))}
            </select>
            <GoDash />
            <select
              value={time?.[item?.id - 1]?.end}
              onChange={(e) => {
                let times = time,
                  findTime = times?.find((d) => d?.id === item?.id);
                findTime.end = e.target?.value;
                let newTimes = times?.map((a) =>
                  a?.id === findTime?.id ? findTime : a
                );
                if (day === "Monday") setMonday(newTimes);
                if (day === "Tuesday") setTuesday(newTimes);
                if (day === "Wednesday") setWednesday(newTimes);
                if (day === "Thursday") setThursday(newTimes);
                if (day === "Friday") setFriday(newTimes);
                if (day === "Saturday") setSaturday(newTimes);
                if (day === "Sunday") setSunday(newTimes);
              }}
              id="countries"
              className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-full tw-font-bold tw-w-fit focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-p-2.5"
            >
              {timeSelect?.slice(0, timeSelect?.length - 1)?.map((item, i) => (
                <option key={i} value={item}>
                  {Number(item?.split(":")[0]) < 12 ? (
                    <>{item} AM</>
                  ) : Number(item?.split(":")[0]) > 12 ? (
                    <>
                      {padZero(Number(item?.split(":")[0]) % 12)}:
                      {item?.split(":")[1]} PM
                    </>
                  ) : (
                    <>{item} PM</>
                  )}
                </option>
              ))}
            </select>
            <div
              className="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-gray-50"
              onClick={() => addItem(item.id)}
            >
              <MdAdd />
            </div>
          </div>
        ))}
    </div>
  );
};

function padZero(number) {
  return number.toString().padStart(2, "0");
}

function add30Minutes(time) {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + 30;
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;
  return `${padZero(newHours)}:${padZero(newMinutes)}`;
}

function splitAvailabilityTime(from, to) {
  const result = [];

  let start = from;
  let end = to;

  while (start <= end) {
    const endTime = add30Minutes(start);
    result?.push(start);
    start = endTime;
  }

  return result;
}

export const SchedulerClient = ({
	day,
	time,
	setMonday,
	setTuesday,
	setWednesday,
	setThursday,
	setFriday,
	setSaturday,
	setSunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
	Sunday,
	setMondayShow,
	setTuesdayShow,
	setWednesdayShow,
	setThursdayShow,
	setFridayShow,
	setSaturdayShow,
	setSundayShow,
	show,
	sortedTime,
}) => {
	const toggle = () => {
		if (day === "Monday") setMondayShow(!show);
		if (day === "Tuesday") setTuesdayShow(!show);
		if (day === "Wednesday") setWednesdayShow(!show);
		if (day === "Thursday") setThursdayShow(!show);
		if (day === "Friday") setFridayShow(!show);
		if (day === "Saturday") setSaturdayShow(!show);
		if (day === "Sunday") setSundayShow(!show);
	};

	return (
		<div className="tw-block tw-max-w-sm tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow tw-mx-auto tw-mb-4">
			<div className="tw-flex tw-items-center tw-justify-between tw-p-6">
				<h5 className="tw-mb-2 tw-text-lg tw-font-medium tw-tracking-tight tw-text-gray-900">
					{day}
				</h5>
				<label className="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
					<input
						type="checkbox"
						checked={show}
						className="tw-sr-only tw-peer peer"
						onChange={toggle}
					/>
					<div className="tw-w-14 tw-h-7 tw-bg-gray-200 peer-focus:tw-outline-none peer-focus:tw-ring-4 peer-focus:tw-ring-blue-300 dark:peer-focus:tw-ring-blue-800 tw-rounded-full peer dark:tw-bg-gray-700 peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-left-[4px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-6 after:tw-w-6 after:tw-transition-all dark:tw-border-gray-600 peer-checked:tw-bg-blue-600"></div>
				</label>
			</div>
			{show && (
				<>
					{sortedTime && sortedTime?.length > 0 && (
						<div className="tw-grid-cols-3 tw-grid tw-gap-3 tw-p-3">
							{sortedTime
								// ?.filter(list => !list?.picked?.includes(item?.date))
								?.map((list, i) => (
									<button
										onClick={() => {
											if (day === "Monday") setMonday(list?.start);
											if (day === "Tuesday") setTuesday(list?.start);
											if (day === "Wednesday") setWednesday(list?.start);
											if (day === "Thursday") setThursday(list?.start);
											if (day === "Friday") setFriday(list?.start);
											if (day === "Saturday") setSaturday(list?.start);
											if (day === "Sunday") setSunday(list?.start);
										}}
										type="button"
										className={`tw-px-4 tw-py-2 tw-rounded-md tw-border tw-border-plain tw-text-xs tw-whitespace-nowrap hover:tw-bg-plain hover:tw-text-white tw-w-full ${
											day === "Monday"
												? Monday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										} ${
											day === "Tuesday"
												? Tuesday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										} ${
											day === "Wednesday"
												? Wednesday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										}${
											day === "Thursday"
												? Thursday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										}${
											day === "Friday"
												? Friday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										}${
											day === "Saturday"
												? Saturday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										}${
											day === "Sunday"
												? Sunday === list?.start
													? "tw-text-white tw-bg-plain"
													: ""
												: ""
										}`}
										key={i}>
										{moment(list?.start, "HH:mm:ss").format("LT")}
									</button>
								))}
						</div>
					)}
				</>
			)}
		</div>
	);
};